import {
  GET_CATEGORIES,
  GET_MANUFACTURERS,
  GET_MODELS,
  GET_MACHINES,
  GET_MACHINE,
  GET_QUOTATION_DATA,
  GET_MACHINES_CAROUSEL,
  GET_QUOTATION_DESTINATIONS
} from './../actions/types'

export const categoriesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return action.payload
    default:
      return state
  }
}

export const manufacturersReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MANUFACTURERS:
      return action.payload
    default:
      return state
  }
}

export const modelsManufacturer = (state = [], action) => {
  switch (action.type) {
    case GET_MODELS:
      return action.payload
    default:
      return state
  }
}

export const machinesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MACHINES:
      return action.payload
    default:
      return state
  }
}

export const machineDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MACHINE:
      return action.payload
    default:
      return state
  }
}

export const machineQuotationReducer = (state = [], action) => {
  switch (action.type) {
    case GET_QUOTATION_DATA:
      return action.payload
    default:
      return state
  }
}

export const quotationDestinationsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_QUOTATION_DESTINATIONS:
      return action.payload
    default:
      return state
  }
}

export const machinesCarouselReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MACHINES_CAROUSEL:
      return action.payload
    default:
      return state
  }
}