import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col  } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import _ from 'lodash'

import { ReduxFormSelect, ReduxFormRadioCheckbox } from './../form-fields'
import { getQuotationDestinations, getMachineQuotationData } from './../../redux/actions'

/**
 * Creates a set of grouped options for a "Select" component.
 * @param {String} usaLabel 
 * @param {Array} usaArray 
 * @param {String} mexLabel 
 * @param {Array} mexArray 
 */
const setOptionsGroup = (usaLabel, usaArray = [], mexLabel, mexArray = []) => {
  if (!usaArray.length) {
    return [
      {
        label: usaLabel,
        options: []
      },
      {
        label: mexLabel,
        options: []
      }
    ]
  }

  return [
    {
      label: usaLabel,
      options: usaArray.map(item => ({
        label: item.destination,
        value: item.stateId
      }))
    },
    {
      label: mexLabel,
      options: mexArray.map(item => ({
        label: item.destination,
        value: item.stateId
      }))
    }
  ]
}

const QuotationForm = (props) => {
  const [currentLang, setCurrentLang] = useState(undefined)
  const [selectedDestination, setSelectedDestination] = useState({})
  const [platform, setPlatform] = useState({})
  const [includeInsurance, setIncludeInsurance] = useState(false)
  const [quotationValues, setQuotationValues] = useState({})

  const { state, country, stateId, priceNumeric } = props.machineData
  const { destinations, language, quotation, getQuotationDestinations, getMachineQuotationData } = props
  const { yes, no } = props.dictionary.general
  const {
    description,
    origin,
    destination,
    platformType,
    shippingInsurance,
    price,
    shipment,
    fee,
    handling,
    total,
    note,
    destinationTo,
    platformsList
  } = props.dictionary.modules.machinery.quotation

  const groupedDestinations = setOptionsGroup(destinationTo.usa, props.destinations.usa, destinationTo.mex, props.destinations.mex)

  useEffect(() => {
    if (_.isEmpty(destinations) || currentLang !== language) {
      getQuotationDestinations({
        lang: language
      })
      setCurrentLang(language)
    } else {
      const destinationStateId = selectedDestination.value || destinations.usa[0].stateId
      getMachineQuotationData({
        originStateId: stateId,
        destinationStateId,
        lang: language
      })
    }
  }, [destinations, currentLang, selectedDestination])

  useEffect(() => {
    if (!_.isEmpty(quotation)) {
      const platformType = platform.value || platformsList[0].value
      const usaShipping = quotation[0].distance * quotation[0][platformType]
      const insurance = includeInsurance
        ? ((parseInt(priceNumeric) + usaShipping) * 0.0055) + 150
        : 0
      let mexShipping = 0
      let mexFee = 0
      let mexHandling = 0

      if (quotation.length > 1) {
        mexShipping = quotation[1].distance * quotation[1][platformType]
        mexFee = quotation[1]['feeBase'] + (priceNumeric * quotation[1]['feeFactor'])
        mexHandling = mexFee * quotation[1][`${platformType}Factor`]
      }

      const total = parseInt(priceNumeric) + usaShipping + insurance + mexShipping + mexFee + mexHandling
      const destinationName = selectedDestination.label || destinations.usa[0].destination
      const platformName = platform.label || platformsList[0].label
      const quotationData = {
        origin: `${state}, ${country}`,
        destination: destinationName,
        platform: platformName,
        usaShipping,
        insurance,
        mexShipping,
        mexFee,
        mexHandling,
        total
      }

      setQuotationValues(quotationData)
      props.onChange(quotationData)
    }
  }, [quotation, platform, includeInsurance])

  const handleDestinationChange = destination => {
    setSelectedDestination(destination)
  }

  const handlePlatformTypeChange = platformType => {
    setPlatform(platformType)
  }

  const handleInsuranceChange = isInsuranceRequired => {
    setIncludeInsurance(isInsuranceRequired)
  }

  return (
    <div className="form quotation">
      <Row>
        <Col>
          { description }
        </Col>
      </Row>
      <Row>
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { origin }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          { state }, { country }
        </Col>
      </Row>
      <Row>
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { destination }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          <Field
            name="destination"
            options={ groupedDestinations }
            component={ ReduxFormSelect }
            onChange={ handleDestinationChange }
            isLoading={ _.isEmpty(props.destinations) }
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { platformType }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          <Field
            name="platform"
            options={ platformsList }
            component={ ReduxFormSelect }
            onChange={ handlePlatformTypeChange }
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { shippingInsurance }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          <div className="radio-group">
            <Field
              name="insurance"
              type="radio"
              label={ yes }
              value={ true }
              checked={ includeInsurance }
              component={ ReduxFormRadioCheckbox }
              onChange={ handleInsuranceChange }
            />
            <Field
              name="insurance"
              type="radio"
              label={ no }
              value={ false }
              checked={ !includeInsurance }
              component={ ReduxFormRadioCheckbox }
              onChange={ handleInsuranceChange }
            />
          </div>
        </Col>
      </Row>
      <Row className="details">
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { price }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          <NumberFormat
            value={ (Math.round(priceNumeric * 100) / 100).toFixed(2) }
            displayType={ 'text' }
            thousandSeparator={ true }
            prefix={ '$ ' }
            suffix={ ' USD' }
          />
        </Col>
      </Row>
      <Row className="details">
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { shipment.usa }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          <NumberFormat
            value={ (Math.round(quotationValues.usaShipping * 100) / 100).toFixed(2) }
            displayType={ 'text' }
            thousandSeparator={ true }
            prefix={ '$ ' }
            suffix={ ' USD' }
          />
        </Col>
      </Row>
      { quotationValues.insurance > 0 && (
        <Row className="details">
          <Col
            xs={ 12 }
            md={ 4 }
            className="label"
          >
            { shippingInsurance }
          </Col>
          <Col
            xs={ 12 }
            md={ 8 }
            className="value"
          >
            <NumberFormat
              value={ (Math.round(quotationValues.insurance * 100) / 100).toFixed(2) }
              displayType={ 'text' }
              thousandSeparator={ true }
              prefix={ '$ ' }
              suffix={ ' USD' }
            />
          </Col>
        </Row>
      ) }
      { quotationValues.mexShipping > 0 && (
        <Row className="details">
          <Col
            xs={ 12 }
            md={ 4 }
            className="label"
          >
            { shipment.mex }
          </Col>
          <Col
            xs={ 12 }
            md={ 8 }
            className="value"
          >
            <NumberFormat
              value={ (Math.round(quotationValues.mexShipping * 100) / 100).toFixed(2) }
              displayType={ 'text' }
              thousandSeparator={ true }
              prefix={ '$ ' }
              suffix={ ' USD' }
            />
          </Col>
        </Row>
      ) }
      { quotationValues.mexFee > 0 && (
        <Row className="details">
          <Col
            xs={ 12 }
            md={ 4 }
            className="label"
          >
            { fee }
          </Col>
          <Col
            xs={ 12 }
            md={ 8 }
            className="value"
          >
            <NumberFormat
              value={ (Math.round(quotationValues.mexFee * 100) / 100).toFixed(2) }
              displayType={ 'text' }
              thousandSeparator={ true }
              prefix={ '$ ' }
              suffix={ ' USD' }
            />
          </Col>
        </Row>
      ) }
      { quotationValues.mexFee > 0 && (
        <Row className="details">
          <Col
            xs={ 12 }
            md={ 4 }
            className="label"
          >
            { handling }
          </Col>
          <Col
            xs={ 12 }
            md={ 8 }
            className="value"
          >
            <NumberFormat
              value={ (Math.round(quotationValues.mexHandling * 100) / 100).toFixed(2) }
              displayType={ 'text' }
              thousandSeparator={ true }
              prefix={ '$ ' }
              suffix={ ' USD' }
            />
          </Col>
        </Row>
      ) }
      <Row className="details">
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { total }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          <NumberFormat
            value={ (Math.round(quotationValues.total * 100) / 100).toFixed(2) }
            displayType={ 'text' }
            thousandSeparator={ true }
            prefix={ '$ ' }
            suffix={ ' USD' }
          />
        </Col>
      </Row>
      <Row className="note">
        <Col xs={ 12 }>
          { note }
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
    dictionary: state.dictionary,
    quotation: state.machineQuotation,
    destinations: state.quotationDestinations
  }
}

const reduxConnect = connect(
  mapStateToProps,
  { getQuotationDestinations, getMachineQuotationData }
)(QuotationForm)

export default reduxForm({
  form: 'quotationForm',
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(reduxConnect)