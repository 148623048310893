import React from 'react'
import Select from 'react-select'

export const ReduxFormInput = ({ input, meta, label, placeholder, type='text', className='', autoComplete='off' }) => {
  const inputClassName = `input ${meta.touched && meta.error ? 'error' : ''} ${className}`.trim()

  return (
    <div className="field">
      { label && (
        <label>{ label }:</label>
      ) }
      <input
        className={ inputClassName }
        type={ type }
        placeholder={ placeholder }
        autoComplete={ autoComplete }
        { ...input }
      />
    </div>
  )
}

export const ReduxFormRadioCheckbox = ({ input, meta, label, type='radio', checked=false, className='' }) => {
  const inputClassName = `radio-checkbox ${meta.touched && meta.error ? 'error' : ''} ${className}`.trim()
  input.checked = checked

  const handleOptionSelected = e => {
    const value = ['true', 'false'].includes(e.target.value)
    ? e.target.value === 'true'
    : e.target.value
    input.value = value
    input.onChange(value)
  }

  return (
    <div className="field">
      <input
        className={ inputClassName }
        type={ type }
        { ...input }
        onChange={ handleOptionSelected }
      />
      { label && (
        <label>{ label }</label>
      ) }
    </div>
  )
}

export const ReduxFormSelect = ({ input, meta, label, placeholder, options, isLoading, isSearchable, className='' }) => {
  const isGroupedOptions = !!options[0].options
  const isDisabled = (isGroupedOptions && !options[0].options.length) || (!isGroupedOptions && !options.length)
  const inputClassName = `select ${meta.touched && meta.error ? 'error' : ''} ${className}`.trim()

  const formatGroupLabel = data => (
    <div className="select-grouped-options">
      <span>{ data.label }</span>
      <span>{ data.options.length }</span>
    </div>
  )

  const handleOptionSelected = value => {
    input.onChange(value)
    input.value = value
  }

  if (!placeholder && !input.value) {
    input.value = isGroupedOptions ? options[0].options[0] : options[0]
  }

  return (
    <div className="field">
      { label && (
        <label>{ label }:</label>
      ) }
      <Select
        className={ inputClassName }
        placeholder={ placeholder }
        { ...input }
        options={ options }
        formatGroupLabel={ formatGroupLabel }
        isLoading={ isLoading }
        isDisabled={ isDisabled }
        isSearchable={ isSearchable }
        onChange={ (value) => handleOptionSelected(value) }
        onBlur={ () => input.onBlur(input.value) }
      />
    </div>
  )
}
