import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faPhoneAlt, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { Modal, Button, ButtonGroup } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import _ from 'lodash'

import LoadingSpinner from './../LoadingSpinner'

const MachineryData = (props) => {
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [showSharingModal, setShowSharingModal] = useState(false)
  const [sharedMachineInfo, setSharedMachineInfo] = useState(null)
  const machineryDictionary = props.dictionary.modules.machinery
  const navigatorDictionary = props.dictionary.navigator

  // Set Social Media data.
  const cellNumber = '5215515108955'
  const whatsappBaseURL = isMobile ? 'https://api.whatsapp.com/send' : 'https://wa.me/'
  const facebookBaseURL = 'https://www.facebook.com/sharer/sharer.php?u=https://imcmexico.mx'
  const twitterBaseURL = 'http://www.twitter.com/share?url=https://imcmexico.mx'
  const whatsappContactMessage = `${whatsappBaseURL}${isMobile ? `?phone=${cellNumber}&` : `${cellNumber}?`}text=${machineryDictionary.requestMessage}`

  if (props.isLoading && !props.machines.length) {
    return (
      <LoadingSpinner showLabel />
    )
  } else if (props.isLoading && props.machines.length && !showLoadingModal) {
    setShowLoadingModal(true)
  } else if (!props.isLoading && showLoadingModal) {
    setShowLoadingModal(false)
  }

  const handleShowSharingModal = machineData => {
    setSharedMachineInfo(machineData)
    setShowSharingModal(true)
  }

  const handleCloseSharingModal = () => {
    setShowSharingModal(false)
    setSharedMachineInfo(null)
  }

  const handleShareDetails = socialMediaType => {
    let shareURL = null

    switch (socialMediaType) {
      case 'whatsapp':
        shareURL = `${whatsappBaseURL}?text=${machineryDictionary.sharingMessage}%0A%0D${sharedMachineInfo.info}`
        break
      case 'facebook':
        shareURL = `${facebookBaseURL}${sharedMachineInfo.link}&quote=${machineryDictionary.sharingMessage}`
        break
      case 'twitter':
        shareURL = `${twitterBaseURL}${sharedMachineInfo.link}&text=${machineryDictionary.sharingMessage}%0A%0D%0A%0D`
        break
      default:
    }

    window.open(shareURL)
    handleCloseSharingModal()
  }

  return (
    <Fragment>
      <Modal show={ showLoadingModal } animation={ false }>
        <Modal.Header>
          <Modal.Title>{ machineryDictionary.loadingModal.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingSpinner />
        </Modal.Body>
      </Modal>
      <Modal size="sm" show={ showSharingModal } onHide={ handleCloseSharingModal } animation={ false }>
        <Modal.Header closeButton>
          <Modal.Title>{ machineryDictionary.sharingModal.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="social-network">
            <Button
              className="whatsapp"
              onClick={ () => handleShareDetails('whatsapp') }
            >
              <FontAwesomeIcon
                icon={['fab', 'whatsapp']}
                size='lg'
              />
              <span>WhatsApp</span>
            </Button>
            <Button
              className="facebook"
              onClick={ () => handleShareDetails('facebook') }
            >
              <FontAwesomeIcon
                icon={['fab', 'facebook']}
                size='lg'
              />
              <span>Facebook</span>
            </Button>
            <Button
              className="twitter"
              onClick={ () => handleShareDetails('twitter') }
            >
              <FontAwesomeIcon
                icon={['fab', 'twitter']}
                size='lg'
              />
              <span>Twitter</span>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="machinery-section">
        { props.machines.map(machine => {
          const categoryInfo = `*${machineryDictionary.category}:* ${machine.category}%0A%0D`
          const manufacturerInfo = `*${machineryDictionary.manufacturer}:* ${machine.manufacturer}%0A%0D`
          const modelInfo = `*${machineryDictionary.model}:* ${machine.model}%0A%0D`
          const referenceInfo = `*${machineryDictionary.reference}:* ${machine.reference}%0A%0D`
          const machineDetailsLink = `/${navigatorDictionary.machinery.toLowerCase()}/${_.kebabCase(machine.category)}/${_.kebabCase(machine.manufacturer)}/${_.kebabCase(machine.model)}/${machine.reference}`
          const urlInfo = `*URL:* https://imcmexico.mx${machineDetailsLink}`
          const machineInfo = `${categoryInfo}${manufacturerInfo}${modelInfo}${referenceInfo}${urlInfo}`
          const contactInfoMessage = `${whatsappContactMessage}%0A%0D${machineInfo}`
          return (
            <div
              key={ machine.reference }
              className="col-12 col-md-6 col-lg-4">
              <div className="machine-card">
                <div className="photo">
                  <img src={ machine.photo } alt={ machine.reference } />
                  <span className="img-info">
                    <span className="price">{ machine.price } USD</span>
                  </span>
                </div>
                <div className="body">
                  <h3 className='title'>Ref: { machine.reference }</h3>
                  <p><b>{ machineryDictionary.category }:</b> { machine.category }</p>
                  <p><b>{ machineryDictionary.manufacturer }:</b> { machine.manufacturer }</p>
                  <p><b>{ machineryDictionary.model }:</b> { machine.model }</p>
                  <p><b>{ machineryDictionary.description }:</b></p>
                  <div className="description">
                    <p className="ellipsis-block">
                      { machine.description }
                    </p>
                  </div>
                  <Link
                    to={ machineDetailsLink }
                    className="view-details"
                  >
                    { machineryDictionary.viewDetails }
                  </Link>
                  <div className="attributes">
                    <p><b>{ machineryDictionary.year }:</b> { machine.year }</p>
                    <p><b>{ machineryDictionary.hours }:</b> { machine.hours }</p>
                  </div>
                </div>
                <div className="footer">
                  <ButtonGroup>
                    <a
                      href={ machineDetailsLink }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn link"
                    >
                      <FontAwesomeIcon
                        icon={ faLink }
                        size='lg'
                      />
                    </a>
                    <a
                      href="tel:5215515108955"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn phone"
                    >
                      <FontAwesomeIcon
                        icon={ faPhoneAlt }
                        size='lg'
                      />
                    </a>
                    <a
                      href={ contactInfoMessage }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn whatsapp"
                    >
                      <FontAwesomeIcon
                        icon={['fab', 'whatsapp']}
                        size='lg'
                      />
                    </a>
                    <Button
                      className="btn share"
                      onClick={ () => handleShowSharingModal({ info: machineInfo, link: machineDetailsLink }) }
                    >
                      <FontAwesomeIcon
                        icon={ faShareAlt }
                        size='lg'
                      />
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          )
        }) }
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(
  mapStateToProps,
  {}
)(MachineryData)