import React from 'react'
import { connect } from 'react-redux'

const Footer = (props) => {
  return (
    <footer>
      { props.dictionary.footer.label }
    </footer>
  )
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(
  mapStateToProps,
  {}
)(Footer)