import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import Pagination from 'react-js-pagination'
import { Alert } from 'react-bootstrap'
import _ from 'lodash'

import { getCategories, getManufacturers, getModels, getMachines } from './../../redux/actions'
import Breadcrumbs from './../layout/Breadcrumbs'
import FilterForm from './FilterForm'
import MachineryData from './MachineryData'
import history from './../../helpers/history'
import './../../styles/machines.css'

const MachineryList = (props) => {
  // Set current language and define the language dictionary.
  const lang = props.language
  const machineryDictionary = props.dictionary.modules.machinery
  const navigatorDictionary = props.dictionary.navigator
  const propsUrlParams = props.match.params

  // Set Items to Display per page.
  const itemsCountPerPage = 12

  // Set component states.
  const [urlParams, setUrlParams] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [machinesParams, setMachinesParams] = useState({})
  const [category, setCategory] = useState({
    initialValue: null,
    name: null,
    path: null,
    evaluated: false,
    isValid: false
  })
  const [manufacturer, setManufacturer] = useState({
    initialValue: null,
    name: null,
    path: null,
    evaluated: false,
    isValid: false
  })
  const [model, setModel] = useState({
    initialValue: null,
    name: null,
    path: null,
    evaluated: false,
    isValid: false
  })
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    {
      label: navigatorDictionary.home,
      url: '/'
    },
    {
      label: navigatorDictionary.machinery
    }
  ])
  const [machinesList, setMachinesList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [dimensions, setDimensions] = useState({ height: window.innerHeight, width: window.innerWidth })

  //Set the number of items to display for pagination.
  let pageRangeDisplayed = 3

  if (dimensions.width >= 1024) {
    pageRangeDisplayed = 12
  } else if (dimensions.width >= 768) {
    pageRangeDisplayed = 8
  }

  document.title = `IMCMEXICO | ${machineryDictionary.title}`

  // Destructuring machines params.
  const { categoryName, manufacturerName, modelName, isOnlyMexico, orderBy, isOrderDesc } = machinesParams

  useEffect(() => {
    // Fetch the categories list.
    props.getCategories({
      basedOnMachines: true
    })
  }, []) // Once the Component is Mounted.

  useEffect(() => {
    // Check the URL params to verify if they need to be re-validated or not.
    validateUrlParams()
  }, [propsUrlParams, props.topCategories, props.topManufacturers, props.topModels])

  useEffect(() => {
    const isCategoriesSet = !propsUrlParams.category || category.evaluated
    const isManufacturerSet = !propsUrlParams.manufacturer || manufacturer.evaluated
    const isModelSet = !propsUrlParams.model || model.evaluated
    const paramsReady = !_.isEmpty(machinesParams) && isCategoriesSet && isManufacturerSet && isModelSet
    
    // Fetch the machines list based on the machine params and the current page.
    if (paramsReady) {
      if (propsUrlParams.category && category.evaluated && !category.isValid) {
        setErrorMessage(`${_.startCase(propsUrlParams.category)} ${machineryDictionary.invalid.category}`)
      } else if (propsUrlParams.manufacturer && manufacturer.evaluated && !manufacturer.isValid) {
        setErrorMessage(`${_.startCase(propsUrlParams.manufacturer)} ${machineryDictionary.invalid.manufacturer}`)
      } else if (propsUrlParams.model && model.evaluated && !model.isValid) {
        setErrorMessage(`${_.startCase(propsUrlParams.model)} ${machineryDictionary.invalid.model}`)
      }

      props.getMachines({
        lang,
        categoryName,
        manufacturerName,
        modelName,
        currentPage,
        itemsCountPerPage,
        isOnlyMexico,
        orderBy,
        isOrderDesc
      })

      // Turn on the "is loading" flag.
      setIsLoading(true)
    }
  }, [currentPage, machinesParams]) // Update Component if "current page" or "machine params" states are updated.

  useEffect(() => {
    // Check if it is needed to turn off the "is loading" flag once the "machines" props has been fetched.
    if (isLoading && props.machines && !props.machines.length) {
      setMachinesList(props.machines)
      setIsLoading(false)
    } else if (isLoading && props.machines && props.machines.length) {
      const lastIndex = props.machines.length - 1
      const isSameContent = machinesList.length &&
        props.machines.length === machinesList.length &&
        props.machines[lastIndex].reference === machinesList[lastIndex].reference &&
        props.machines[lastIndex].category === machinesList[lastIndex].category

      // Turn off the "is loading" flag once the "machines list" state has been updated.
      if (!machinesList.length || !isSameContent) {
        setMachinesList(props.machines)
        setIsLoading(false)
      } else if (isSameContent) {
        setIsLoading(false)
      }
    }
  }, [props.machines]) // Update Component if "machines" props has been updated.

  useEffect(()=> {
    // Define screen dimensions in case of resizing.
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }
    
    window.addEventListener('resize', handleResize)
  }, [dimensions]) // Update Component if "dimensions" state is updated.

  useEffect(() => {
    translateContent()
  }, [lang]) // Update Component if the "language" prop is updated.

  /**
   * Initialize an element: Category, Manufacturer or Model.
   */
  const initializeElement = () => {
    return {
      initialValue: null,
      name: null,
      path: null,
      evaluated: true,
      isValid: false
    }
  }

  /**
   * Get the equivalence of a param (category, manufacturer, model) in case of switching of language or 
   * when the url includes any of those params.
   * @param {String} lang 
   * @param {String} paramName 
   * @param {Array} topOptions 
   * @param {Array} otherOptions 
   */
  const getParamData = (lang, paramName, topOptions = [], otherOptions = []) => {
    if (paramName) {
      // Check the type of the top options array to validate the proper name field.
      const nameFieldType = typeof topOptions[0].name

      // Verify if the param exists into the "top options" array.
      const top = topOptions.find(option => {
        if (nameFieldType === 'string') {
          return _.kebabCase(option.name) === paramName 
        }
        return [_.kebabCase(option.name.es), _.kebabCase(option.name.en)].includes(paramName)
      })

      // Verify if the param exists into the "other options" array (ONLY if it doesn't exist into the "top options" array).
      const other = !top ? otherOptions.find(option => {
        if (nameFieldType === 'string') {
          return _.kebabCase(option.name) === paramName 
        }
        return [_.kebabCase(option.name.es), _.kebabCase(option.name.en)].includes(paramName)
      }) : null

      // Store in a var the found object (if applies).
      const data = top || other

      // Set and return an object with the initial value (filter prop), name (breadcrumbs), path (redirect url) for the evaluated param.
      if (data) {
        const dataValue = nameFieldType === 'string' ? data.name : data.name[lang]
        return {
          initialValue: { label: dataValue, value: dataValue },
          name: dataValue,
          path: _.kebabCase(dataValue),
          evaluated: true,
          isValid: true
        }
      }
    }

    // Return an object with no initial value (filter prop), no name (breadcrumbs), no path (redirect url) for the evaluated param.
    return initializeElement()
  }

  /**
   * Redirects to a path based on the category, manufacturer and model
   * @param {String} base 
   * @param {String} category 
   * @param {String} manufacturer 
   * @param {String} model 
   */
  const redirect = (base, categoryPath, manufacturerPath, modelPath) => {
    const urlItems = [
      `/${base.toLowerCase()}`
    ]

    if (categoryPath) {
      urlItems.push(categoryPath)

      if (manufacturerPath) {
        urlItems.push(manufacturerPath)

        if (modelPath) {
          urlItems.push(modelPath)
        }
      }
    }

    history.push(urlItems.join('/'))
  }

  /**
   * Re-render the machines list if the current url params are different of the previous url params.
   */
  const validateUrlParams = () => {
    if (!_.isEqual(propsUrlParams, urlParams)) {
      // Clear the error message if exists.
      if (errorMessage) {
        setErrorMessage('')
      }
  
      // Create a copy of the current elements to work with their properties directly.
      let currentBreadcrumbs = _.cloneDeep(breadcrumbItems)
      let currentCategory = _.cloneDeep(category)
      let currentManufacturer = _.cloneDeep(manufacturer)
      let currentModel = _.cloneDeep(model)
      let currentMachinesParams = _.isEmpty(machinesParams) ? { itemsCountPerPage } : _.cloneDeep(machinesParams)
  
      // Remove unused data for the machines params according with the URL params.
      if (!propsUrlParams.category) {
        if (currentCategory.name) {
          // Remove the "Category", "Manufacturer" and "Model" elements from the "Breadcrumbs" and remove the URL property for "Machinery".
          currentBreadcrumbs.splice(2, 3)
          delete(currentBreadcrumbs[1].url)
    
          // Remove the "Category", "Manufacturer" and "Model" elements from the "Machines Params".
          delete(currentMachinesParams.categoryName)
          delete(currentMachinesParams.manufacturerName)
          delete(currentMachinesParams.modelName)
        }

        // Initialize the "Category", "Manufacturer" and "Model" elements.
        currentCategory = initializeElement()
        currentManufacturer = initializeElement()
        currentModel = initializeElement()
      } else if (!propsUrlParams.manufacturer) {
        if (currentManufacturer.name) {
          // Remove the "Manufacturer" and "Model" elements from the "Breadcrumbs" and remove the URL property for "Category".
          currentBreadcrumbs.splice(3, 2)
          delete(currentBreadcrumbs[2].url)
    
          // Remove the "Manufacturer" and "Model" elements from the "Machines Params".
          delete(currentMachinesParams.manufacturerName)
          delete(currentMachinesParams.modelName)
        }

        // Initialize the "Manufacturer" and "Model" elements.
        currentManufacturer = initializeElement()
        currentModel = initializeElement()
      } else if (!propsUrlParams.model) {
        if (currentModel.name) {
          // Remove the "Model" element from the "Breadcrumbs" and remove the URL property for "Manufacturer".
          currentBreadcrumbs.splice(4, 1)
          delete(currentBreadcrumbs[3].url)
    
          // Remove the "Model" element from the "Machines Params".
          delete(currentMachinesParams.modelName)
        }

        // Initialize the "Model" element.
        currentModel = initializeElement()
      }

      currentCategory.evaluated = propsUrlParams.category === currentCategory.path
      currentCategory.isValid = propsUrlParams.category !== currentCategory.path ? false : currentCategory.isValid

      // Fetch the Category data according with the URL param (only if it changed).
      if (propsUrlParams.category && props.topCategories && !currentCategory.evaluated) {
        currentCategory = getParamData(lang, propsUrlParams.category, props.topCategories, props.categories)
  
        // Exclude the validations for manufacturer and model because the category is invalid.
        if (!currentCategory.path) {
          currentManufacturer.evaluated = true
          currentModel.evaluated = true
        } else {
          currentMachinesParams.categoryName = currentCategory.name
        }
      }
      
      if (propsUrlParams.category && currentCategory.isValid) {
        // Set url for the "machinery" item and add the "category" item into the Breadcrumbs.
        currentBreadcrumbs[1].url = `/${navigatorDictionary.machinery.toLowerCase()}`

        if (currentBreadcrumbs.length >= 3) {
          currentBreadcrumbs[2].label = currentCategory.name
          currentBreadcrumbs[2].url = currentBreadcrumbs.length > 3
            ?  `/${navigatorDictionary.machinery.toLowerCase()}/${currentCategory.path}`
            : undefined
        } else {
          currentBreadcrumbs.push({
            label: currentCategory.name
          })
        }
      }

      currentManufacturer.evaluated = propsUrlParams.manufacturer === currentManufacturer.path
      currentManufacturer.isValid = propsUrlParams.manufacturer !== currentManufacturer.path ? false : currentManufacturer.isValid

      // Fetch the Manufacturer data according with the URL param (only if it changed and the Category is valid).
      if (propsUrlParams.manufacturer && currentCategory.name && (props.topManufacturers || []).length && !currentManufacturer.evaluated) {
        currentManufacturer = getParamData(lang, propsUrlParams.manufacturer, props.topManufacturers, props.manufacturers)
  
        // Exclude the validation for model because the manufacturer is invalid.
        if (!currentManufacturer.path) {
          currentModel.evaluated = true
        } else {
          currentMachinesParams.manufacturerName = currentManufacturer.name
        }
      }

      if (propsUrlParams.manufacturer && currentManufacturer.isValid) {
        // Set url for the "category" item and add the "manufacturer" item into the Breadcrumbs.
        currentBreadcrumbs[2].url = `/${navigatorDictionary.machinery.toLowerCase()}/${currentCategory.path}`

        if (currentBreadcrumbs.length >= 4) {
          currentBreadcrumbs[3].label = currentManufacturer.name
          currentBreadcrumbs[3].url = currentBreadcrumbs.length > 4
            ?  `/${navigatorDictionary.machinery.toLowerCase()}/${currentCategory.path}/${currentManufacturer.path}`
            : undefined
        } else {
          currentBreadcrumbs.push({
            label: currentManufacturer.name
          })
        }
      }

      currentModel.evaluated = propsUrlParams.model === currentModel.path
      currentModel.isValid = propsUrlParams.model !== currentModel.path ? false : currentModel.isValid
  
      // Fetch the Model data according with the URL param (only if it changed and the Category and Manufacturer are valid).
      if (propsUrlParams.model && currentCategory.name && currentManufacturer.name && (props.topModels || []).length && !currentModel.evaluated) {
        currentModel = getParamData(lang, propsUrlParams.model, props.topModels, props.models)
  
        if (currentModel.path) {
          currentMachinesParams.modelName = currentModel.name
        }
      }

      if (propsUrlParams.model && currentModel.isValid) {
        // Set url for the "manufacturer" item and add the "model" item into the Breadcrumbs.
        currentBreadcrumbs[3].url = `/${navigatorDictionary.machinery.toLowerCase()}/${currentCategory.path}/${currentManufacturer.path}`

        if (currentBreadcrumbs.length > 4) {
          currentBreadcrumbs[4].label = currentModel.name
        } else {
          currentBreadcrumbs.push({
            label: currentModel.name
          })
        }
      }

      // Set the "URL Params" state with the corresponding values from the props once all the elements has been evaluated.
      if (currentCategory.evaluated && currentManufacturer.evaluated && currentModel.evaluated) {
        setUrlParams(propsUrlParams)
      }

      // Set the corresponding state values.
      setCategory(currentCategory)
      setManufacturer(currentManufacturer)
      setModel(currentModel)
      setBreadcrumbItems(currentBreadcrumbs)
      setMachinesParams(currentMachinesParams)
    }
  }

  const translateContent = () => {
    // Set "initial values" for filter form and the "machine params" when the categories array has been fetched.
    if (props.topCategories) {
      const breadcrumbs = breadcrumbItems
      const categoryData = getParamData(lang, propsUrlParams.category, props.topCategories, props.categories)

      breadcrumbs[1].label = navigatorDictionary.machinery

      if (breadcrumbs.length >= 3) {
        breadcrumbs[2].label = categoryData.name
      } else {
        breadcrumbs.push({
          label: categoryData.name
        })
      }

      setCategory(categoryData)
      // setBreadcrumbItems(breadcrumbItems)
      redirect(navigatorDictionary.machinery, categoryData.path, manufacturer.path, model.path)
  
      setMachinesParams({
        ...machinesParams,
        categoryName: categoryData.name
      })
    }
  }

  /**
   * Hides the error message (only if it is displayed).
   */
  const handleHideError = () => {
    if (errorMessage) {
      setErrorMessage(null)
    }
  }

  /**
   * Updates the URL and make request for fetching new machines list based on the user's filtering.
   * @param {Object} formValues 
   */
  const onSubmit = formValues => {
    if (!_.isEmpty(formValues)) {
      const { category, manufacturer, model, isOnlyMexico, orderBy, orderDesc } = formValues
      const isOrderDesc = !orderDesc
        ? true
        : orderDesc === 'true'
      
      handleHideError()
      redirect(navigatorDictionary.machinery, _.kebabCase((category || {}).value), _.kebabCase((manufacturer || {}).value), _.kebabCase((model || {}).value))

      setMachinesParams({
        categoryName: (category || {}).value,
        manufacturerName: (manufacturer || {}).value,
        modelName: (model || {}).value,
        isOnlyMexico: (isOnlyMexico || {}).value || false,
        orderBy: (orderBy || {}).value || 'price',
        isOrderDesc
      })
      setCurrentPage(1)
    }
  }

  /**
   * Make a request for fetching a new result page based on the current list.
   * @param {Number} pageNumber 
   */
  const handlePaginationChange = pageNumber => {
    setIsLoading(true)
    setCurrentPage(pageNumber)
  }

  return (
    <Fragment>
      <Breadcrumbs items={ breadcrumbItems } />
      { errorMessage && (
        <Alert
          variant="danger"
          onClose={ handleHideError }
          dismissible
        >
          { errorMessage }
        </Alert>
      ) }
      <FilterForm
        initialValues={{
          category: category.initialValue,
          manufacturer: manufacturer.initialValue,
          model: model.initialValue
        }}
        // initialValues={ initialValues }
        onSubmit={ onSubmit }
      />
      <MachineryData
        isLoading={ isLoading }
        machines={ machinesList }
      />
      { props.machines && props.totalPages > 1 && (
        <Pagination
          hideFirstLastPages={ true }
          activePage={ currentPage }
          itemsCountPerPage={ itemsCountPerPage }
          totalItemsCount={ parseInt(props.totalMachines) }
          pageRangeDisplayed={ pageRangeDisplayed }
          onChange={ handlePaginationChange }
        />
      ) }
    </Fragment>
  )
}

const mapStateToProps = state => {
  const { topCategories, categories } = state.categories
  const { topManufacturers, manufacturers } = state.manufacturers
  const { topModels, models } = state.models
  const { machines, totalMachines, totalPages } = state.machinesData
  return {
    language: state.language.id,
    dictionary: state.dictionary,
    topCategories,
    categories,
    topManufacturers,
    manufacturers,
    topModels,
    models,
    machines,
    totalMachines,
    totalPages
  }
}

export default connect(
  mapStateToProps,
  { getCategories, getManufacturers, getModels, getMachines }
)(MachineryList)