import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { languageReducer, dictionaryReducer } from './language'
import {
  categoriesReducer,
  manufacturersReducer,
  modelsManufacturer,
  machinesReducer,
  machineDetailsReducer,
  machineQuotationReducer,
  quotationDestinationsReducer,
  machinesCarouselReducer
} from './machines'

export default combineReducers({
  form: formReducer,
  language: languageReducer,
  dictionary: dictionaryReducer,
  categories: categoriesReducer,
  manufacturers: manufacturersReducer,
  models: modelsManufacturer,
  machinesData: machinesReducer,
  machineDetails: machineDetailsReducer,
  machineQuotation: machineQuotationReducer,
  quotationDestinations: quotationDestinationsReducer,
  machinesCarousel: machinesCarouselReducer
})