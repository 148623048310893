import React, { useContext, Fragment, useState } from 'react'
import { connect } from 'react-redux'

import { languageOptions  } from './../../languages'
import { LanguageContext } from './../../context/Language'
import { setLanguage, setDictionary } from './../../redux/actions'

const LanguageSelector = (props) => {
  const languageContext = useContext(LanguageContext)
  const [oppositeLanguage, setOppositeLanguage] = useState(languageOptions.find(item => item.id !== languageContext.language.id).text)

  const handleToggleLanguage = () => {
    const selectedLanguage = languageOptions.find(item => item.text === oppositeLanguage)
    languageContext.setLanguage(selectedLanguage)
    setOppositeLanguage(languageOptions.find(item => item.id !== selectedLanguage.id).text)

    props.setLanguage(selectedLanguage)
    props.setDictionary(selectedLanguage.id)
  }

  return (
    <Fragment>
      <p className="language" onClick={ handleToggleLanguage }>{ oppositeLanguage }</p>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language
  }
}

export default connect(
  mapStateToProps,
  { setLanguage, setDictionary }
)(LanguageSelector)