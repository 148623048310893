import React from 'react'
import axios from 'axios'
import Moment from 'react-moment'
import NumberFormat from 'react-number-format'
import { Font, Page, Image, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import _ from 'lodash'

import 'moment/locale/es';
import monserratFont from './../../fonts/Montserrat-ExtraLight.ttf'
import monserratBoldFont from './../../fonts/Montserrat-Medium.ttf'

// Import Fonts
Font.register({
  family: 'Montserrat',
  src: monserratFont,
  format: "truetype"
})

Font.register({
  family: 'Montserrat-Bold',
  src: monserratBoldFont,
  format: "truetype"
})

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 16,
    position: 'relative',
    fontSize: 9,
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    flexDirection: 'column',
    backgroundColor: '#ffffff'
  },
  sections: {
    header: {
      flexDirection: 'row'
    },
    title: {
      flexDirection: 'column'
    },
    machine: {
      card: {
        marginTop: 16,
        marginBottom: 8,
        flexDirection: 'row'
      },
      mainPhoto: {
        margin: 0,
        padding: 0,
        width: '50%',
        border: '1px solid #cccccc',
        borderRight: '0 solid #ffffff',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      photo: {
        width: '290px',
        height: '196px',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        objectFit: 'cover'
      },
      details: {
        margin: 0,
        padding: 8,
        width: '50%',
        border: '1px solid #cccccc',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        flexDirection: 'column'
      },
      detailsRow: {
        marginBottom: 4,
        flexDirection: 'row'
      },
      category: {
        width: '100%',
        fontFamily: 'Montserrat-Bold'
      },
      label: {
        width: 116,
        fontFamily: 'Montserrat-Bold'
      },
      value: {
        width: 'calc(100% - 116px)'
      }
    },
    description: {
      card: {
        marginTop: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '1px solid #cccccc',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        flexWrap: 'wrap',
        textAlign: 'justify'
      },
      title: {
        padding: 8,
        width: '100%',
        fontFamily: 'Montserrat-Bold',
        color: '#ffffff',
        backgroundColor: 'rgb(66, 133, 244)',
        textAlign: 'left'
      },
      content: {
        padding: 8,
        width: '100%'
      }
    },
    descriptionRow: {
      label: {
        marginBottom: 4,
        width: '100%',
        fontFamily: 'Montserrat-Bold'
      },
      value: {
        marginBottom: 4,
        width: '100%'
      }
    },
    quotation: {
      card: {
        marginTop: 8,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '1px solid #cccccc',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
      title: {
        padding: 8,
        width: '100%',
        fontFamily: 'Montserrat-Bold',
        color: '#ffffff',
        backgroundColor: 'rgb(66, 133, 244)',
        textAlign: 'left'
      },
      row: {
        padding: '4px 8px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      rowEven: {
        padding: '4px 8px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'rgb(207, 224, 252)'
      },
      label: {
        width: '40%',
        fontFamily: 'Montserrat-Bold'
      },
      value: {
        width: '60%',
        display: 'flex',
        textAlign: 'right'
      }
    },
    photos: {
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    photo: {
      margin: 8,
      padding: 0,
      width: 170,
      height: 102,
      borderRadius: 4,
      objectFit: 'cover'
    },
    footer: {
      marginTop: 16,
      width: '100%',
      borderTop: '1px solid #000',
      position: 'absolute',
      bottom: 16,
      left: 16,
      right: 16
    }
  },
  logo: {
    marginRight: 8,
    width: 30,
    height: 30
  },
  h1: {
    marginBottom: 4,
    fontFamily: 'Montserrat-Bold',
    fontSize: '11px',
    fontWeight: 700
  }
})

// Create Document Component
const MachineDetailsPdf = (props) => {
  const machineryDictionary = props.dictionary
  const logo = `${process.env.PUBLIC_URL}/IMC_Logo.png`
  const {
    category,
    manufacturer,
    model,
    reference,
    year,
    hours,
    description,
    state,
    country,
    machineContact,
    machineContactPhone1,
    machineContactPhone2,
    machineContactCell,
    machineContactEmail,
    photos,
    priceNumeric
  } = props.machine
  const {
    origin,
    destination,
    platform,
    usaShipping,
    insurance,
    mexShipping,
    mexFee,
    mexHandling,
    total
  } = props.quotation || {}

  const photosClone = _.cloneDeep(photos)
  let mainPhoto = undefined

  if (photosClone.length) {
    mainPhoto = photosClone[0].photo
    photosClone.shift()
  }

  const totalRowOdd = (insurance === 0 && mexShipping === 0) || (insurance > 0 && mexShipping > 0)

  return (
    <Document
      title={ `${category} ${manufacturer} ${model} ${reference}` }
      author="IMCMEXICO"
      creator="Fernando Daniel Colon Gonzalez"
    >
      <Page size="letter" style={styles.page}>
        <View style={ styles.sections.header } fixed>
          <Image src={ logo } style={ styles.logo } />
          <View style={ styles.sections.title }>
            <Text style={ styles.h1 }>{ machineryDictionary.pdf.title }</Text>
            <Text style={ styles.h3 }>
              { machineryDictionary.pdf.subtitle }
              <Moment
                locale={ props.lang }
                format={ machineryDictionary.pdf.dateFormat }
              >
                { new Date() }
              </Moment>
            </Text>
          </View>
        </View>
        <View style={ styles.sections.machine.card }>
          <View style={ styles.sections.machine.mainPhoto }>
            { mainPhoto && (
              <Image
                src={ mainPhoto }
                style={ styles.sections.machine.photo }
                cache={ false }
                crossorigin="https://imcmexico.mx"
              />
            ) }
          </View>
          <View style={ styles.sections.machine.details }>
            <View style={ styles.sections.machine.detailsRow }>
              <Text style={ styles.sections.machine.category }>{ category }</Text>
            </View>
            <View style={ styles.sections.machine.detailsRow }>
              <Text style={ styles.sections.machine.label }>{ machineryDictionary.manufacturer }</Text>
              <Text style={ styles.sections.machine.value }>{ manufacturer }</Text>
            </View>
            <View style={ styles.sections.machine.detailsRow }>
              <Text style={ styles.sections.machine.label }>{ machineryDictionary.model }</Text>
              <Text style={ styles.sections.machine.value }>{ model }</Text>
            </View>
            <View style={ styles.sections.machine.detailsRow }>
              <Text style={ styles.sections.machine.label }>{ machineryDictionary.reference }</Text>
              <Text style={ styles.sections.machine.value }>{ reference }</Text>
            </View>
            <View style={ styles.sections.machine.detailsRow }>
              <Text style={ styles.sections.machine.label }>{ machineryDictionary.year }</Text>
              <Text style={ styles.sections.machine.value }>{ year }</Text>
            </View>
            <View style={ styles.sections.machine.detailsRow }>
              <Text style={ styles.sections.machine.label }>{ machineryDictionary.hours }</Text>
              <Text style={ styles.sections.machine.value }>{ hours }</Text>
            </View>
            <View style={ styles.sections.machine.detailsRow }>
              <Text style={ styles.sections.machine.label }>{ machineryDictionary.machineLocation }</Text>
              <Text style={ styles.sections.machine.value }>{ state }, { country }</Text>
            </View>
            <View style={ styles.sections.machine.detailsRow }>
              <Text style={ styles.sections.machine.label }>{ machineryDictionary.contact.name }</Text>
              <Text style={ styles.sections.machine.value }>{ machineContact }</Text>
            </View>
            <View style={ styles.sections.machine.detailsRow }>
              <Text style={ styles.sections.machine.label }>{ machineryDictionary.contact.phone.main }</Text>
              <Text style={ styles.sections.machine.value }>{ machineContactPhone1 }</Text>
            </View>
            { machineContactPhone2 && (
              <View style={ styles.sections.machine.detailsRow }>
                <Text style={ styles.sections.machine.label }>{ machineryDictionary.contact.phone.secondary }</Text>
                <Text style={ styles.sections.machine.value }>{ machineContactPhone2 }</Text>
              </View>
            ) }
            { machineContactCell && (
              <View style={ styles.sections.machine.detailsRow }>
                <Text style={ styles.sections.machine.label }>{ machineryDictionary.contact.phone.cell }</Text>
                <Text style={ styles.sections.machine.value }>{ machineContactCell }</Text>
              </View>
            ) }
            { machineContactEmail && (
              <View style={ styles.sections.machine.detailsRow }>
                <Text style={ styles.sections.machine.label }>{ machineryDictionary.contact.email }</Text>
                <Text style={ styles.sections.machine.value }>{ machineContactEmail }</Text>
              </View>
            ) }
          </View>
        </View>
        <View style={ styles.sections.description.card }>
          <Text style={ styles.sections.description.title }>{ machineryDictionary.description }</Text>
          <Text style={ styles.sections.description.content }>{ description }</Text>
        </View>
        { !!usaShipping && (
          <View style={ styles.sections.quotation.card }>
            <Text style={ styles.sections.quotation.title }>{ machineryDictionary.quotation.quotation }</Text>
            <View style={ styles.sections.quotation.row }>
              <Text style={ styles.sections.quotation.label }>{ machineryDictionary.quotation.origin }</Text>
              <Text style={ styles.sections.quotation.value }>{ origin }</Text>
            </View>
            <View style={ styles.sections.quotation.rowEven }>
              <Text style={ styles.sections.quotation.label }>{ machineryDictionary.quotation.destination }</Text>
              <Text style={ styles.sections.quotation.value }>{ destination }</Text>
            </View>
            <View style={ styles.sections.quotation.row }>
              <Text style={ styles.sections.quotation.label }>{ machineryDictionary.quotation.platformType }</Text>
              <Text style={ styles.sections.quotation.value }>{ platform }</Text>
            </View>
            <View style={ styles.sections.quotation.rowEven }>
              <Text style={ styles.sections.quotation.label }>{ machineryDictionary.quotation.price }</Text>
              <Text style={ styles.sections.quotation.value }>
                <NumberFormat
                  value={ (Math.round(priceNumeric * 100) / 100).toFixed(2) }
                  displayType={ 'text' }
                  thousandSeparator={ true }
                  prefix={ '$ ' }
                  suffix={ ' USD' }
                />
              </Text>
            </View>
            <View style={ styles.sections.quotation.row }>
              <Text style={ styles.sections.quotation.label }>{ machineryDictionary.quotation.shipment.usa }</Text>
              <Text style={ styles.sections.quotation.value }>
                <NumberFormat
                  value={ (Math.round(usaShipping * 100) / 100).toFixed(2) }
                  displayType={ 'text' }
                  thousandSeparator={ true }
                  prefix={ '$ ' }
                  suffix={ ' USD' }
                  renderText={value => <span>{ value }</span>}
                />
              </Text>
            </View>
            { insurance > 0 && (
              <View style={ styles.sections.quotation.rowEven }>
                <Text style={ styles.sections.quotation.label }>{ machineryDictionary.quotation.shippingInsurance }</Text>
                <Text style={ styles.sections.quotation.value }>
                  <NumberFormat
                    value={ (Math.round(insurance * 100) / 100).toFixed(2) }
                    displayType={ 'text' }
                    thousandSeparator={ true }
                    prefix={ '$ ' }
                    suffix={ ' USD' }
                    renderText={value => <span>{ value }</span>}
                  />
                </Text>
              </View>
            ) }
            { mexShipping > 0 && (
              <View style={ insurance > 0 ? styles.sections.quotation.row : styles.sections.quotation.rowEven }>
                <Text style={ styles.sections.quotation.label }>{ machineryDictionary.quotation.shipment.mex }</Text>
                <Text style={ styles.sections.quotation.value }>
                  <NumberFormat
                    value={ (Math.round(mexShipping * 100) / 100).toFixed(2) }
                    displayType={ 'text' }
                    thousandSeparator={ true }
                    prefix={ '$ ' }
                    suffix={ ' USD' }
                    renderText={value => <span>{ value }</span>}
                  />
                </Text>
              </View>
            ) }
            { mexFee > 0 && (
              <View style={ insurance === 0 ? styles.sections.quotation.row : styles.sections.quotation.rowEven }>
                <Text style={ styles.sections.quotation.label }>{ machineryDictionary.quotation.fee }</Text>
                <Text style={ styles.sections.quotation.value }>
                  <NumberFormat
                    value={ (Math.round(mexFee * 100) / 100).toFixed(2) }
                    displayType={ 'text' }
                    thousandSeparator={ true }
                    prefix={ '$ ' }
                    suffix={ ' USD' }
                    renderText={value => <span>{ value }</span>}
                  />
                </Text>
              </View>
            ) }
            { mexHandling > 0 && (
              <View style={ insurance > 0 ? styles.sections.quotation.row : styles.sections.quotation.rowEven }>
                <Text style={ styles.sections.quotation.label }>{ machineryDictionary.quotation.handling }</Text>
                <Text style={ styles.sections.quotation.value }>
                  <NumberFormat
                    value={ (Math.round(mexHandling * 100) / 100).toFixed(2) }
                    displayType={ 'text' }
                    thousandSeparator={ true }
                    prefix={ '$ ' }
                    suffix={ ' USD' }
                    renderText={value => <span>{ value }</span>}
                  />
                </Text>
              </View>
            ) }
            <View style={ !totalRowOdd ? styles.sections.quotation.row : styles.sections.quotation.rowEven }>
              <Text style={ styles.sections.quotation.label }>{ machineryDictionary.quotation.total }</Text>
              <Text style={ styles.sections.quotation.value }>
                <NumberFormat
                  value={ (Math.round(total * 100) / 100).toFixed(2) }
                  displayType={ 'text' }
                  thousandSeparator={ true }
                  prefix={ '$ ' }
                  suffix={ ' USD' }
                  renderText={value => <span>{ value }</span>}
                />
              </Text>
            </View>
          </View>
        ) }
        <View style={ styles.sections.photos }>
          { (photosClone || []).map((item, index) => (
            <Image
              key={ `item${index}` }
              src={ item.photo }
              style={ styles.sections.photo }
              cache={ false }
              crossOrigin="https://imcmexico.mx"
            />
          )) }
        </View>
        <View style={ styles.sections.footer } fixed>
          <Text>
            IMCMEXICO, Copyright © <Moment format="YYYY">{ new Date() }</Moment>
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default MachineDetailsPdf