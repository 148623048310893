import React, { createContext, useState, useContext } from 'react'
import { languageOptions, dictionaryList } from '../languages'

// Create the language context with default selected language.
export const LanguageContext = createContext({
  language: languageOptions[0],
  dictionary: dictionaryList[languageOptions[0].id]
})

export const LanguageProvider = (props) => {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage); // it will update the language in state
      setDictionary(dictionaryList[selectedLanguage.id])
    }
  }

  return (
    <LanguageContext.Provider value={ provider }>
      { props.children }
    </LanguageContext.Provider>
  )
}

export const Translate = (tid) => {
  const languageContext = useContext(LanguageContext)
  return languageContext.dictionary[tid]
}