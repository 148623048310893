import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'

const PageNotFound = (props) => {
  const errorPageDictionary = props.dictionary.modules.pageNotFound
  const navigatorDictionary = props.dictionary.navigator

  return (
    <Fragment>
      <h1>{ errorPageDictionary.title }</h1>
      <p>{ errorPageDictionary.content }</p>
      <ul>
        { errorPageDictionary.links.map(link => {
          return (
          <li key={ link.navRef }>
            <Link to={ `/${_.kebabCase(navigatorDictionary[link.navRef])}` }>
              { link.label }
            </Link>
          </li>
          )
        }) }
      </ul>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(
  mapStateToProps,
  {}
)(PageNotFound)