import React, { Fragment } from 'react'

import history from './../../helpers/history'
import './../../styles/breadcrumbs.css'

const Breadcrumbs = (props) => {
  const handleChangePath = (path) => {
    history.push(path)
  }

  return (
    <div className="breadcrumbs">
      { props.items.map(item => {
        if (item.url) {
          return (
            <Fragment key={ item.label }>
              <span
                className="link"
                onClick={ () => handleChangePath(item.url) }
              >
                { item.label }
              </span>
              <span className="separator">/</span>
            </Fragment>
          )
        }
        return (
          <span
            key={ item.label }
            className="active"
          >
            { item.label }
          </span>
        )
      }) }
    </div>
  )
}

export default Breadcrumbs