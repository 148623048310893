import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Modal, Carousel, Card, ButtonGroup, Button, Alert, Row, Col  } from 'react-bootstrap'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faUserTie,
  faPhoneAlt,
  faMobileAlt,
  faEnvelope,
  faShareAlt,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons'
import { isMobile } from 'react-device-detect'
import _ from 'lodash'

import { getMachine } from './../../redux/actions'
import Breadcrumbs from './../layout/Breadcrumbs'
import LoadingSpinner from './../LoadingSpinner'
import MachineDetailsPdf from './MachineDetailsPdf'
import './../../styles/machines.css'
import QuotationForm from './QuotationForm'

const MachineryDetails = (props) => {
  const navigatorDictionary = props.dictionary.navigator
  const machineryDictionary = props.dictionary.modules.machinery
  const lang = props.language

  const [showSharingModal, setShowSharingModal] = useState(false)
  const [sharedMachineInfo, setSharedMachineInfo] = useState({})
  const [stateReference, setStateReference] = useState(null)
  const [stateLanguage, setStateLanguage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [quotation, setQuotation] = useState(null)
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    {
      label: navigatorDictionary.home,
      url: '/'
    },
    {
      label: navigatorDictionary.machinery
    }
  ])
  
  const {
    category: propCategory,
    manufacturer: propManufacturer,
    model: propModel,
    reference: propReference
  } = props.match.params
  const { machine } = props
  const fileName = `${propCategory}-${propManufacturer}-${propModel}-${propReference}.pdf`

  // Set Social Media data.
  const cellNumber = '5215515108955'
  const whatsappBaseURL = isMobile ? 'https://api.whatsapp.com/send' : 'https://wa.me/'
  const facebookBaseURL = 'https://www.facebook.com/sharer/sharer.php?u=https://imcmexico.mx'
  const twitterBaseURL = 'http://www.twitter.com/share?url=https://imcmexico.mx'
  const whatsappContactMessage = `${whatsappBaseURL}${isMobile ? `?phone=${cellNumber}&` : `${cellNumber}?`}text=${machineryDictionary.requestMessage}`

  document.title = `IMCMEXICO | ${_.startCase(propCategory)} ${_.startCase(propManufacturer)} ${_.startCase(propModel)} ${_.startCase(propReference)}`

  useEffect(() => {
    const { category, manufacturer, model, reference } = props.machine || {}
    const currentBreadcrumbs = _.cloneDeep(breadcrumbItems)

    if (_.isEmpty(props.machine) || lang !== stateLanguage || propReference !== stateReference) {
      props.getMachine(propReference, {
        lang
      })

      if (lang !== stateLanguage) {
        setStateLanguage(lang)
      }
      
      if (propReference !== stateReference) {
        setStateReference(propReference)
      }

    } else if (breadcrumbItems.length === 2) {
      currentBreadcrumbs[1].url = `/${navigatorDictionary.machinery.toLowerCase()}`
      currentBreadcrumbs.push({
        label: category,
        url: `/${navigatorDictionary.machinery.toLowerCase()}/${_.kebabCase(category)}`
      })
      currentBreadcrumbs.push({
        label: manufacturer,
        url: `/${navigatorDictionary.machinery.toLowerCase()}/${_.kebabCase(category)}/${_.kebabCase(manufacturer)}`
      })
      currentBreadcrumbs.push({
        label: model,
        url: `/${navigatorDictionary.machinery.toLowerCase()}/${_.kebabCase(category)}/${_.kebabCase(manufacturer)}/${_.kebabCase(model)}`
      })
      currentBreadcrumbs.push({
        label: reference
      })

      setBreadcrumbItems(currentBreadcrumbs)
    } else {
      currentBreadcrumbs[1].label = navigatorDictionary.machinery
      currentBreadcrumbs[1].url = `/${navigatorDictionary.machinery.toLowerCase()}`
      currentBreadcrumbs[2].label = category
      currentBreadcrumbs[2].url = `/${navigatorDictionary.machinery.toLowerCase()}/${_.kebabCase(category)}/${_.kebabCase(manufacturer)}`
      setBreadcrumbItems(currentBreadcrumbs)
    }

    if (!_.isEmpty(props.machine) && !props.machine.errorMessage) {
      const categoryInfo = `*${machineryDictionary.category}:* ${machine.category}%0A%0D`
      const manufacturerInfo = `*${machineryDictionary.manufacturer}:* ${machine.manufacturer}%0A%0D`
      const modelInfo = `*${machineryDictionary.model}:* ${machine.model}%0A%0D`
      const referenceInfo = `*${machineryDictionary.reference}:* ${machine.reference}%0A%0D`
      const machineDetailsLink = `/${navigatorDictionary.machinery.toLowerCase()}/${_.kebabCase(machine.category)}/${_.kebabCase(machine.manufacturer)}/${_.kebabCase(machine.model)}/${machine.reference}`
      const urlInfo = `*URL:* https://imcmexico.mx${machineDetailsLink}`
      const machineInfo = `${categoryInfo}${manufacturerInfo}${modelInfo}${referenceInfo}${urlInfo}`
      
      setSharedMachineInfo({
        info: machineInfo,
        link: machineDetailsLink
      })
    }
  }, [lang, propReference, props.machine])

  const handleShowSharingModal = () => {
    setShowSharingModal(true)
  }

  const handleCloseSharingModal = () => {
    setShowSharingModal(false)
  }

  const handleShareDetails = socialMediaType => {
    let shareURL = null

    switch (socialMediaType) {
      case 'whatsapp':
        shareURL = `${whatsappBaseURL}?text=${machineryDictionary.sharingMessage}%0A%0D${sharedMachineInfo.info}`
        break
      case 'facebook':
        shareURL = `${facebookBaseURL}${sharedMachineInfo.link}&quote=${machineryDictionary.sharingMessage}`
        break
      case 'twitter':
        shareURL = `${twitterBaseURL}${sharedMachineInfo.link}&text=${machineryDictionary.sharingMessage}%0A%0D%0A%0D`
        break
      default:
    }

    window.open(shareURL)
    handleCloseSharingModal()
  }

  /**
   * Hides the error message (only if it is displayed).
   */
  const handleHideError = () => {
    if (errorMessage) {
      setErrorMessage(null)
    }
  }

  const handleQuotationChange = quotationData => {
    setQuotation(quotationData)
  }

  const renderData = () => {
    if (_.isEmpty(props.machine)) {
      return (
        <LoadingSpinner showLabel />
      )
    } else if (!_.isEmpty(props.machine) && props.machine.errorMessage) {
      const machineNotFound = machineryDictionary.machineNotFound

      if (!errorMessage || errorMessage !== machineNotFound) {
        setErrorMessage(machineNotFound)
      }
      return (
        <Alert
          variant="danger"
          onClose={ handleHideError }
          dismissible
        >
          { machineNotFound }
        </Alert>
      )
    }

    return (
      <div className="machine">
        <Breadcrumbs items={ breadcrumbItems } />
        <div className="machine-details-card">
          <Carousel
            fade={ true }
            indicators={ false }
            className="machine-carousel"
            prevIcon={ <FontAwesomeIcon icon={ faChevronLeft } className="big-arrow" /> }
            nextIcon={ <FontAwesomeIcon icon={ faChevronRight } className="big-arrow" /> }
          >
            { machine && (machine.photos || []).map((item, index) => (
              <Carousel.Item key={ `item${index}` }>
                <img
                  className="d-block w-100 carousel-image"
                  src={ item.photo }
                  alt={ `${machine.category} ${machine.manufacturer} ${machine.model} Ref. ${machine.reference}` }
                />
              </Carousel.Item>
            )) }
          </Carousel>
          <div className="machine-details">
            <h3>{ machine.category }</h3>
            <p><b>{ machineryDictionary.manufacturer }:</b> { machine.manufacturer }</p>
            <p><b>{ machineryDictionary.model }:</b> { machine.model }</p>
            <p><b>{ machineryDictionary.reference }:</b> { machine.reference }</p>
            <p><b>{ machineryDictionary.year }:</b> { machine.year }</p>
            <p><b>{ machineryDictionary.hours }:</b> { machine.hours }</p>
            <p><b>{ machineryDictionary.machineLocation }:</b> { machine.state }, { machine.country }</p>
            <p>
              <FontAwesomeIcon
                icon={ faUserTie }
                size="lg"
                title={ machineryDictionary.contact.name }
                className="icon"
              />
              { machine.machineContact }
            </p>
            <p>
              <FontAwesomeIcon
                icon={ faPhoneAlt }
                size="lg"
                title={ machineryDictionary.contact.phone.main }
                className="icon"
              />
              <a
                href={ `tel:${machine.machineContactPhone1}` }
                target="_blank"
                rel="noopener noreferrer"
              >
                { machine.machineContactPhone1 }
              </a>
            </p>
            { machine.machineContactPhone2 && (
              <p>
                <FontAwesomeIcon
                  icon={ faPhoneAlt }
                  size="lg"
                  title={ machineryDictionary.contact.phone.secondary }
                  className="icon"
                />
                <a
                  href={ `tel:${machine.machineContactPhone2}` }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  { machine.machineContactPhone2 }
                </a>
              </p>
            ) }
            { machine.machineContactCell && (
              <p>
                <FontAwesomeIcon
                  icon={ faMobileAlt }
                  size="lg"
                  title={ machineryDictionary.contact.phone.cell }
                  className="icon"
                />
                <a
                  href={ `tel:${machine.machineContactCell}` }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  { machine.machineContactCell }
                </a>
              </p>
            ) }
            { machine.machineContactEmail && (
              <p>
                <FontAwesomeIcon
                  icon={ faEnvelope }
                  size="lg"
                  title={ machineryDictionary.contact.email }
                  className="icon"
                />
                <a
                  href={ `mail:${machine.machineContactEmail}` }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  { machine.machineContactEmail }
                </a>
              </p>
            ) }
          </div>
        </div>
        <div className="machine-description">
          <Card>
            <Card.Header>{ machineryDictionary.description }</Card.Header>
            <Card.Body>{ machine.description }</Card.Body>
          </Card>
        </div>
        <div className="machine-quotation">
          <Card>
            <Card.Header>{ machineryDictionary.quotation.title }</Card.Header>
            <Card.Body>
              <QuotationForm
                machineData={ machine }
                onChange={ handleQuotationChange }
              />
            </Card.Body>
          </Card>
        </div>
        <div className="machine-footer">
          <ButtonGroup>
            <a
              href={ `${whatsappContactMessage}%0A%0D${sharedMachineInfo.info}` }
              target="_blank"
              rel="noopener noreferrer"
              className="btn whatsapp"
            >
              <FontAwesomeIcon
                icon={['fab', 'whatsapp']}
                size='lg'
              />
              <span className="label">{ machineryDictionary.buttons.request }</span>
            </a>
            <PDFDownloadLink
              document={ 
                <MachineDetailsPdf
                  machine={ machine }
                  quotation={ quotation }
                  lang={ lang }
                  dictionary={ machineryDictionary }
                />
              }
              fileName={ fileName }
              className="btn pdf"
            >
              {({ blob, url, loading, error }) => (
                <Fragment>
                  <FontAwesomeIcon
                    icon={ faFilePdf }
                    size='lg'
                  />
                  <span className="label">
                    { loading ? machineryDictionary.buttons.pdf.loading : machineryDictionary.buttons.pdf.download }
                  </span>
                </Fragment>
              )}
            </PDFDownloadLink>
            <Button
              className="btn share"
              onClick={ handleShowSharingModal }
            >
              <FontAwesomeIcon
                icon={ faShareAlt }
                size='lg'
              />
              <span className="label">{ machineryDictionary.buttons.share }</span>
            </Button>
          </ButtonGroup>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      <Modal size="sm" show={ showSharingModal } onHide={ handleCloseSharingModal } animation={ false }>
        <Modal.Header closeButton>
          <Modal.Title>{ machineryDictionary.sharingModal.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="social-network">
            <Button
              className="whatsapp"
              onClick={ () => handleShareDetails('whatsapp') }
            >
              <FontAwesomeIcon
                icon={['fab', 'whatsapp']}
                size='lg'
              />
              <span>WhatsApp</span>
            </Button>
            <Button
              className="facebook"
              onClick={ () => handleShareDetails('facebook') }
            >
              <FontAwesomeIcon
                icon={['fab', 'facebook']}
                size='lg'
              />
              <span>Facebook</span>
            </Button>
            <Button
              className="twitter"
              onClick={ () => handleShareDetails('twitter') }
            >
              <FontAwesomeIcon
                icon={['fab', 'twitter']}
                size='lg'
              />
              <span>Twitter</span>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      { renderData() }
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language.id,
    dictionary: state.dictionary,
    machine: state.machineDetails
  }
}

export default connect(
  mapStateToProps,
  { getMachine }
)(MachineryDetails)