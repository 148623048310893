import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button  } from 'react-bootstrap'

import { getCategories, getManufacturers, getModels } from './../../redux/actions'
import { ReduxFormSelect, ReduxFormRadioCheckbox }  from './../form-fields'
import './../../styles/forms.css'

/**
 * Creates a set of grouped options for a "Select" component.
 * @param {String} topOptionsLabel 
 * @param {Array} topOptionsArray 
 * @param {String} otherOptionsLabel 
 * @param {Array} otherOptionsArray 
 */
const setOptionsGroup = (lang, topOptionsLabel, topOptionsArray = [], otherOptionsLabel, otherOptionsArray = []) => {
  if (!topOptionsArray.length) {
    return [
      {
        label: topOptionsLabel,
        options: []
      },
      {
        label: otherOptionsArray,
        options: []
      }
    ]
  }

  const nameFieldType = typeof topOptionsArray[0].name
  
  if (nameFieldType === 'object') {
    otherOptionsArray.sort((a, b) => a.name[lang] < b.name[lang] ? -1 : 1)
  }

  return [
    {
      label: topOptionsLabel,
      options: topOptionsArray.map(item => ({
        label: nameFieldType === 'string' ? item.name : item.name[lang],
        value: nameFieldType === 'string' ? item.name : item.name[lang]
      }))
    },
    {
      label: otherOptionsLabel,
      options: otherOptionsArray.map(item => ({
        label: nameFieldType === 'string' ? item.name : item.name[lang],
        value: nameFieldType === 'string' ? item.name : item.name[lang]
      }))
    }
  ]
}

const FilterForm = (props) => {
  // Set current language and define the language dictionary.
  const lang = props.language
  const generalDictionary = props.dictionary.general
  const machineryDictionary = props.dictionary.modules.machinery
  const defaultCategory = props.initialValues.category
  const defaultManufacturer = props.initialValues.manufacturer

  // Set component states.
  const [currentLanguage, setCurrentLanguage] = useState(lang)
  const [category, setCategory] = useState(null)
  const [manufacturer, setManufacturer] = useState(null)
  const [model, setModel] = useState(null)
  const [isDescending, setIsDescending] = useState(true)
  const [loadingManufacturers, setLoadingManufacturers] = useState(false)
  const [loadingModels, setLoadingModels] = useState(false)

  // Set the grouped options for the categories, manufacturers and models.
  const groupedCategories = setOptionsGroup(lang, machineryDictionary.topCategories, props.topCategories, machineryDictionary.otherCategories, props.categories)
  const groupedManufacturers = setOptionsGroup(lang, machineryDictionary.topManufacturers, props.topManufacturers, machineryDictionary.otherManufacturers, props.manufacturers)
  const groupedModels = setOptionsGroup(lang, machineryDictionary.topModels, props.topModels, machineryDictionary.otherModels, props.models)

  if (lang !== currentLanguage) {
    setCurrentLanguage(lang)
  }

  useEffect(() => {
    if (category && loadingManufacturers) {
      props.getManufacturers({
        categoryName: category.value,
        basedOnMachines: true
      })
    }

    if (manufacturer && loadingModels) {
      props.getModels({
        lang,
        categoryName: category.value,
        manufacturerName: manufacturer.value,
        basedOnMachines: true
      })
    }
  }, [lang, category, manufacturer])

  if (!!defaultCategory && !loadingManufacturers) {
    setCategory(defaultCategory)
    setLoadingManufacturers(true)
  }

  if (!!defaultManufacturer && !loadingModels) {
    setManufacturer(defaultManufacturer)
    setLoadingModels(true)
  }

  const onCategorySelect = categoryData => {
    setCategory(categoryData)
    setLoadingManufacturers(true)
    setManufacturer(null)
    setModel(null)
  }

  const onManufacturerSelect = manufacturerData => {
    setManufacturer(manufacturerData)
    setLoadingModels(true)
    setModel(null)
  }

  const onModelSelect = modelData => {
    setModel(modelData)
  }

  const onSortChange = isSortDesc => {
    setIsDescending(isSortDesc)
  }

  const onSubmit = formValues => {
    props.onSubmit(formValues)
  }

  return (
    <form
      className="form"
      onSubmit={ props.handleSubmit(onSubmit) }
    >
      <Row>
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { machineryDictionary.category }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          <Field
            name="category"
            placeholder={ machineryDictionary.placeholderCategory }
            component={ ReduxFormSelect }
            options={ groupedCategories }
            onChange={ onCategorySelect }
            isLoading={ !props.categories }
            isSearchable={ false }
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { machineryDictionary.manufacturer }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          <Field
            name="manufacturer"
            placeholder={ machineryDictionary.placeholderManufacturer }
            options={ groupedManufacturers }
            component={ ReduxFormSelect }
            onChange={ onManufacturerSelect }
            isLoading={ props.categories && category && !props.manufacturers }
            isSearchable={ false }
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { machineryDictionary.model }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          <Field
            name="model"
            placeholder={ machineryDictionary.placeholderModel }
            options={ groupedModels }
            component={ ReduxFormSelect }
            onChange={ onModelSelect }
            isLoading={ props.manufacturers && manufacturer && !props.models }
            isSearchable={ false }
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={ 12 }
          md={ 4 }
          className="label"
        >
          { machineryDictionary.sorting.byPrice }
        </Col>
        <Col
          xs={ 12 }
          md={ 8 }
          className="value"
        >
          <div className="radio-group">
            <Field
              name="orderDesc"
              type="radio"
              label={ generalDictionary.ascending }
              value={ false }
              checked={ !isDescending }
              component={ ReduxFormRadioCheckbox }
              onChange={ onSortChange }
            />
            <Field
              name="orderDesc"
              type="radio"
              label={ generalDictionary.descending }
              value={ true }
              checked={ isDescending }
              component={ ReduxFormRadioCheckbox }
              onChange={ onSortChange }
            />
          </div>
        </Col>
      </Row>
      <div className="button-section">
        <Button
          type="submit"
          className="submit"
        >
          { machineryDictionary.doFilter }
        </Button>
      </div>
    </form>
  )
}

const mapStateToProps = state => {
  const { topCategories, categories } = state.categories
  const { topManufacturers, manufacturers } = state.manufacturers
  const { topModels, models } = state.models
  return {
    language: state.language.id,
    dictionary: state.dictionary,
    topCategories,
    categories,
    topManufacturers,
    manufacturers,
    topModels,
    models
  }
}

const reduxConnect = connect(
  mapStateToProps,
  { getCategories, getManufacturers, getModels }
)(FilterForm)

const validate = formValues => {
  const errors = {}
  return errors
}

export default reduxForm({
  form: 'filterForm',
  validate,
  keepDirtyOnReinitialize: true, // Required to work with initial values
  enableReinitialize: true // Required to work with initial values
})(reduxConnect)