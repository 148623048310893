import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import html from 'react-inner-html'
import { Carousel  } from 'react-bootstrap'
import _ from 'lodash'

import { getMachinesCarousel } from './../redux/actions'
import LoadingSpinner from './LoadingSpinner'
import './../styles/home.css'

const Home = (props) => {
  const navigatorDictionary = props.dictionary.navigator
  const homeDictionary = props.dictionary.modules.home
  const machineryDictionary = props.dictionary.modules.machinery
  document.title = `IMCMEXICO | Venta de máquinas para construcción usadas`

  useEffect(() => {
    props.getMachinesCarousel()
  }, [])

  const renderCarousel = () => {
    if (!props.carousel.length) {
      return (
        <LoadingSpinner />
      )
    }

    return (
      <Carousel
        fade={ true }
        indicators={ false }
        className="home-carousel"
      >
        { props.carousel.map((item, index) => (
          <Carousel.Item key={ `item${index}` }>
            <img
              className="d-block w-100 carousel-image"
              src={ item.photo }
              alt={ `${item.category} ${item.manufacturer} ${item.model} Ref. ${item.reference}` }
            />
            <Carousel.Caption>
              <h3>Ref. { item.reference }</h3>
              <p><b>{ machineryDictionary.category }:</b> { item.category }</p>
              <p><b>{ machineryDictionary.manufacturer }:</b> { item.manufacturer }</p>
              <p><b>{ machineryDictionary.model }:</b> { item.model }</p>
              <p><b>{ machineryDictionary.year }:</b> { item.year }</p>
              <p><b>{ machineryDictionary.hours }:</b> { item.hours }</p>
              <Link
                to={ `/${navigatorDictionary.machinery.toLowerCase()}/${_.kebabCase(item.category)}/${_.kebabCase(item.manufacturer)}/${_.kebabCase(item.model)}/${_.kebabCase(item.reference)}` }
                className=""
              >
                { machineryDictionary.viewDetails }
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
        )) }
      </Carousel>
    )
  }

  return (
    <Fragment>
      <h1>{ homeDictionary.title }</h1>
      <div { ...html(homeDictionary.content) }></div>
      { renderCarousel() }
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    carousel: state.machinesCarousel
  }
}

export default connect(
  mapStateToProps,
  { getMachinesCarousel }
)(Home)