import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Navbar, Nav } from 'react-bootstrap'
import _ from 'lodash'

import LanguageSelector from './LanguageSelector'
import { Link } from 'react-router-dom'

const Header = (props) => {
  const dictionary = props.dictionary.navigator
  const logo = `${process.env.PUBLIC_URL}/IMC_Logo.png`

  const [navExpanded, setNavExpanded] = useState(false)

  const handleToggleNav = expanded => {
    setNavExpanded(expanded)
  }

  const handleCloseNav = () => {
    setNavExpanded(false)
  }

  return (
    <Navbar className="header" expand="lg" sticky="top" variant="dark" onToggle={ handleToggleNav } expanded={ navExpanded } >
      <Navbar.Brand href="/">
        <img src={ logo } className="logo" alt="logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="header-navbar" />
      <Navbar.Collapse id="header-navbar">
        <Nav className="mr-auto">
          <Nav.Item onClick={ handleCloseNav }>
            <Link
              to={ `/${_.kebabCase(dictionary.machinery)}` }
              className="nav-link"
            >
              { dictionary.machinery }
            </Link>
          </Nav.Item>
          <Nav.Item onClick={ handleCloseNav }>
            <Link
              to={ `/${_.kebabCase(dictionary.machinery)}/covid-equipos` }
              className="nav-link"
            >
              { dictionary.covid }
            </Link>
          </Nav.Item>
          <Nav.Item onClick={ handleCloseNav }>
            <Link
              to={ `/${_.kebabCase(dictionary.aboutUs)}` }
              className="nav-link"
            >
              { dictionary.aboutUs }
            </Link>
          </Nav.Item>
          <Nav.Item onClick={ handleCloseNav }>
            <Link
              to={ `/${_.kebabCase(dictionary.faq)}` }
              className="nav-link"
            >
              { dictionary.faq }
            </Link>
          </Nav.Item>
          <Nav.Link
            href="https://www.imcmexico.com.mx/registro-de-datos-para-la-publicacion-gratuita-de-maquinaria"
            target="_blank"
            onClick={ handleCloseNav }
          >
            { dictionary.advertiseHere }
          </Nav.Link>
          <Nav.Link
            href="https://www.imcmexico.com.mx/formulario-de-registro"
            target="_blank"
            onClick={ handleCloseNav }
          >
            { dictionary.signUp }
          </Nav.Link>
          <Nav.Link
            href="https://www.imcmexico.com.mx"
            target="_blank"
            onClick={ handleCloseNav }
          >
            { dictionary.signIn }
          </Nav.Link>
          {/* <Nav.Item onClick={ handleCloseNav }>
            <Link
              to={ `/${_.kebabCase(dictionary.help)}` }
              className="nav-link"
            >
              { dictionary.help }
            </Link>
          </Nav.Item> */}
          {/* <Nav.Item onClick={ handleCloseNav }>
            <LanguageSelector />
          </Nav.Item> */}
        </Nav>
        {/* <Nav>
          <Nav.Link href="/">
            { dictionary.signUp }
          </Nav.Link>
          <Nav.Link href="/">
            { dictionary.signIn }
          </Nav.Link>
        </Nav> */}
      </Navbar.Collapse>
    </Navbar>
  )
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(
  mapStateToProps,
  {}
)(Header)