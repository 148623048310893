import { SET_LANGUAGE, SET_DICTIONARY } from './../actions/types'
import { languageOptions, dictionaryList } from './../../languages'

const defaultLanguage = languageOptions[0]

export const languageReducer = (state = defaultLanguage, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload
    default:
      return state
  }
}

export const dictionaryReducer = (state = dictionaryList[defaultLanguage.id], action) => {
  switch (action.type) {
    case SET_DICTIONARY:
      return action.payload
    default:
      return state
  }
}