import _ from 'lodash'

const es = {
  location: 'Ubicación',
  showAllMachines: 'Global',
  showOnlyMexico: 'Sólo en México',
  includeRecentlySold: 'Incluir Máquinas Vendidas Recientemete',
  general: {
    loading: 'Cargando...',
    yes: 'Sí',
    no: 'No',
    ascending: 'Ascendente',
    descending: 'Descendente'
  },
  navigator: {
    home: 'Home',
    covid: 'Equipos COVID-19',
    machinery: 'Maquinaria',
    catalog: 'Catálogo',
    search: 'Búsqueda',
    request: 'Solicitud',
    publish: 'Publicar',
    aboutUs: 'Nosotros',
    faq: 'Preguntas Frecuentes',
    help: 'Ayuda',
    advertiseHere: 'Anúnciate Aquí',
    signUp: 'Registrarse',
    signIn: 'Iniciar Sesión',
    signOut: 'Cerrar Sesión',
    language: 'English'
  },
  footer: {
    label: 'IMCMEXICO es una marca registrada',
  },
  modules: {
    home: {
      title: 'Bienvenido(a) a IMCMEXICO',
      content: `<p>Para consultar y cotizar rápidamente cualquier oportunidad de maquinaria usada de los Estados Unidos de América. Equipe su obra siguiedo estos tres sencillos pasos:</p>
        <ol>
          <li>
            Encuentre y cotice instantáneamente la máquina que desee desde nuestro portal, a través de nuestro módulo <b>Maquinaria</b>.
          </li>
          <li>
            Lo esperamos en USA para inspeccionar la máquina de su interés. O si lo prefiere, la inspeccionamos por usted.
          </li>
          <li>
            Pague su máquina y recíbala en su puerta u obra en menos de 5 días. Totalmente legalizada y facturada.
          </li>
        </ol>`
    },
    machinery: {
      title: 'Cátalogo de Maquinaria para Construcción',
      category: 'Tipo',
      manufacturer: 'Marca',
      model: 'Modelo',
      reference: 'Referencia',
      placeholderCategory: 'Seleccione un Tipo',
      placeholderManufacturer: 'Seleccione una Marca',
      placeholderModel: 'Seleccione un Modelo',
      placeholderReference: 'Proporcione una Referencia',
      topCategories: 'Tipos Destacados',
      topManufacturers: 'Marcas Destacadas',
      topModels: 'Modelos Destacados',
      otherCategories: 'Otros Tipos',
      otherManufacturers: 'Otras Marcas',
      otherModels: 'Otros Modelos',
      sorting: {
        byPrice: 'Ordenar por Precio'
      },
      doFilter: 'Filtrar',
      nomenclature: 'Nomenclatura',
      machineAvailable: 'Máquina Disponible',
      machineValidate: 'Verificar Disponibilidad',
      machineNotFound: 'No se encontró ninguna coincidencia.',
      description: 'Descripción',
      year: 'Año',
      hours: 'Horas',
      contact: {
        name: 'Contacto',
        phone: {
          main: 'Tel. Principal',
          secondary: 'Tel. Secundario',
          cell: 'Celular'
        },
        email: 'Email'
      },
      machineLocation: 'Ubicación',
      quotation: {
        title: 'Cotice esta máquina hasta su destino',
        description: 'Seleccione el destino y plataforma deseada para trasladar su equipo y obtenga en instantes una cotización preliminar.',
        quotation: 'Cotización',
        origin: 'Origen',
        destination: 'Destino',
        destinationTo: {
          usa: 'Envíos a América del Sur',
          mex: 'Envíos a México'
        },
        platformType: 'Tipo de Plataforma',
        platformsList: [
          {
            label: 'Flat (hasta 10 ton.)',
            value: 'flat'
          },
          {
            label: 'Semi (hasta 20 ton.)',
            value: 'semi'
          },
          {
            label: 'LowBoy (hasta 40 ton.)',
            value: 'lowboy'
          }
        ],
        shippingInsurance: 'Seguro de Traslado',
        price: 'Precio de la Máquina',
        shipment: {
          usa: 'Flete en USA',
          mex: 'Flete en México',
        },
        fee: 'Honorarios',
        handling: 'Maniobras',
        total: 'Total',
        note: 'Cotización aproximada y válida principalmente para máquinas no sobredimensionadas y de una sola pieza. Precio o beneficios aún negociables.'
      },
      viewDetails: 'Ver Ficha',
      requestMessage: 'Hola, ¿podría darme información sobre la siguiente máquina?',
      sharingMessage: 'Hola, me gustaría compartirte la información sobre la siguiente máquina:',
      buttons: {
        request: 'Solicitar información',
        share: 'Compartir ficha',
        pdf: {
          loading: 'Cargando...',
          download: 'Descargar PDF'
        }
      },
      invalid: {
        category: 'no es un Tipo válido.',
        manufacturer: 'no es una Marca válida.',
        model: 'no es un Modelo válido.'
      },
      loadingModal: {
        title: 'Cargando datos...'
      },
      sharingModal: {
        title: 'Compartir Ficha'
      },
      pdf: {
        title: 'Relación de Gestión y Cotización de Maquinaria',
        subtitle: 'Ficha Técnica generada el ',
        dateFormat: 'dddd, DD [de] MMMM [de] YYYY [a las] hh:mm a'
      }
    },
    aboutUs: {
      title: 'Acerca de IMCMEXICO',
      content: 'Contamos también con un rubro de preguntas frecuentes para cada módulo, el cual está diseñado para aclarar cualquier duda que usted pueda tener y, si usted no encuentra la respuesta a sus preguntas, no dude en contactarnos vía correo electrónico o telefónicamente, IMCMEXICO lo asesorará en cualquier momento que usted lo requiera.',
      mission: {
        title: 'Misión',
        content: `Realizamos servicios de asesoría, inspección, evaluación de máquinas y equipos para la construcción y rehabilitación de obras geohidrotécnicas, hidráulicas, terrestres, hidráulica ambiental, forestal, trituración y de perforación.<br /><br />
        Gestionamos actividades de compra y venta, traslado y trámites aduaneros de maquinaria de acuerdo a las necesidades de despacho que solicite el cliente, así como la gestión completa de importación y exportación de maquinaria con entrega en obra, o en puertos fronterizos de Estados Unidos de Norteamérica.<br /><br />
        Le apoyamos en el rastreo de la mejor oportunidad. La máquina que no encuentre en nuestro portal de IMCMEXICO se la conseguimos al precio más bajo posible del mercado internacional.
        <ul>
          <li>
            Elija opciones entre casi 35,000 máquinas en este portal.
          </li>
          <li>
            En promedio nuestros precios resultan hasta 20% más baratos, porque no pagamos intermediarios y lotes.
          </li>
          <li>
            Todas las máquinas de USA que requieran ser entregadas en puertos americanos se entregan en menos de 72 horas.
          </li>
          <li>
            Todas las máquinas tienen refacciones originales y son inspeccionadas antes de adquirirse.
          </li>
          <li>
            Si requiere solo el servicio de importación, le llevamos su máquina hasta su obra al mejor precio.
          </li>
          <li>
            Si vio alguna máquina en otro lugar, nosotros nos encargamos de toda la gestión de traslado al lugar que nos indique.
          </li>
          <li>
            Somos su mejor opción porque en menos de diez años hemos vendido más de 1,900 máquinas a más de 600 constructores de Latinoamérica
          </li>
        </ul>`
      },
      advantages: {
        title: 'Ventajas',
        content: `<p>IMCMEXICO cuenta con más de 200 convenios suscritos con proveedores, principalmente de USA y Canadá que promocionan sus inventarios en nuestro sistema informático con lo que logramos cubrir la mayoría de los requerimientos de maquinaria usada principalmente para empresas de la construcción mexicana, y restos de países de Latinoamérica.</p>
        <p>Usted puede navegar libremente en el sistema sin necesidad de teclear su nombre de usuario y clave, con lo cual encontrará cotizaciones preliminares de toda la maquinaria usada para la construcción, que proveedores nacionales y del extranjero ponen a disposición para renta o venta, así como información técnica, localización e imágenes que se publican.</p>
        <p>Formar parte de los usuarios registrados de IMCMEXICO no tiene ningún costo, es completamente gratis, y sólo tiene que llenar el Formulario de Registro de Usuarios.</p>
        <p>Al formar parte de los usuarios registrados de IMCMEXICO usted tendrá acceso a los servicios siguientes:</p>
        <ul>
          <li>
            Adquisición de Maquinaria para la Construcción de la mejor calidad localizada en Estados Unidos, Canadá y resto del Mundo.
          </li>
          <li>
            Compra de Maquinaria localizada en México.
          </li>
          <li>
            Renta de Maquinaria localizada en México.
          </li>
          <li>
            Compra de Refacciones y Accesorios en México.
          </li>
          <li>
            Compra de Refacciones y Accesorios localizados en Estados Unidos, Canadá y resto del Mundo.
          </li>
        </ul>
        <p>Además, y como valor agregado para su beneficio, usted podrá realizar diversas operaciones en los módulos de Ingeniería, Maquinaria y Construcción, desde el Panel de Control, entre ellas las siguientes:</p>
        <h6>MAQUINARIA</h6>
        <ul>
          <li>
            Venta y Renta de Maquinaria pudiendo utilizar su propia imagen corporativa.
          </li>
          <li>
            Puesta a disposición de los productos y servicios proporcionados por su empresa pudiendo emplear su propia imagen corporativa.
          </li>
          <li>
            Control total sobre toda la información de su maquinaria, productos y servicios, pudiendo en todo momento decidir si dichos activos son publicados, o si los desea tener en su inventario particular.
          </li>
          <li>
            Gestión directa de las transacciones por concepto de Venta, Renta y prestación de servicios.
          </li>
          <li>
            Delegación de gestión sobre sus productos a IMCMEXICO, con lo cual no tiene que preocuparse en establecer ningún contacto.
          </li>
        </ul>
        <h6>INGENIERÍA</h6>
        <ul>
          <li>
            Publicación de su Semblanza Técnica.
          </li>
          <li>
            Consulta y contacto directo de profesionales y técnicos inscritos en el directorio de semblanzas técnicas publicadas.
          </li>
          <li>
            Publicación de sus Productos de Ingeniería.
          </li>
          <li>
            Publicación de sus Servicios de Ingeniería.
          </li>
          <li>
            Adquirir Servicios de Ingeniería.
          </li>
        </ul>
        <h6>CONSTRUCCIÓN</h6>
        <ul>
          <li>
            Publicación de sus Productos de Construcción.
          </li>
          <li>
            Publicación de sus Servicios de Construcción.
          </li>
          <li>
            Publicación de la Imagen Corporativa de su Empresa.
          </li>
          <li>
            Búsqueda personalizada de Productos y Servicios para la Construcción.
          </li>
        </ul>`
      }
    },
    faq: {
      title: 'Preguntas Frecuentes',
      description: 'Haga clic sobre una pregunta para poder visualizar su respuesta.',
      content: [
        {
          question: '¿Qué mercado cubre IMCMEXICO?',
          answer: 'IMCMEXICO abarca toda la industria de la construcción para empresas y especialistas en México, Estados Unidos, Canadá y el resto del mundo'
        },
        {
          question: '¿Tiene algún convenio IMCMEXICO con las empresas de Estados Unidos, Canadá y el resto del mundo que se anuncian en el portal?',
          answer: 'Nuestro único compromiso con las empresas de Estados Unidos, Canadá y el resto del mundo es ofrecer sus productos y servicios a todo el mercado del sector de la construcción en México a través de nuestro portal.'
        },
        {
          question: '¿Qué tan importantes son las empresas de Estados Unidos, Canadá y resto del mundo que se anuncian en IMCMEXICO?',
          answer: 'Las empresas de Estados Unidos, Canadá y resto del mundo que se anuncian en IMCMEXICO, son empresas de talla internacional, que están comprometidas a brindar sus productos y servicios a todo el sector de la construcción.'
        },
        {
          question: '¿Porqué se ha incrementado la adquisición de maquinaria pesada del extranjero?',
          answer: 'La estabilidad económica actual en Latinoamérica, permite que se realicen más construcciones, especialmente en caminos y grandes construcciones y porque en Latinoamérica casi no existen fábricas de maquinaria pesada.'
        },
        {
          question: '¿Cómo puede apoyar IMCMEXICO a sus clientes que requieren adquirir maquinaria?',
          answer: 'En IMCMEXICO día con día mejoramos nuestro sistema informático e invertimos cada vez más recursos para la promoción de maquinaria pesada de nuestros proveedores a través de nuestra página Web y de esta forma cubrir la mayoría de las empresas de la construcción.'
        },
        {
          question: 'Las cotizaciones de la maquinaria que aparece en el portal de IMCMEXICO, ¿son reales?',
          answer: 'Sí, los precios que manejamos en nuestro portal, son precios de lista, que pueden variar para su beneficio dependiendo del acuerdo al que se llegue con el vendedor, en el momento que usted decida adquirir el equipo.'
        },
        {
          question: '¿Cuánto tiempo estarán vigentes las promociones que aparecen en la tabla de servicios y costos?',
          answer: 'Las promociones estarán vigentes durante todo el año.'
        },
        {
          question: 'Si requiero de alguna máquina que no esté en el portal de IMCMEXICO, ¿me pueden asesorar para conseguirla?',
          answer: 'Si usted necesita una máquina que no se encuentre en nuestro portal, sólo tiene que dar clic en Solicitudes, que aparece en el panel izquierdo, proceder a llenarla y nosotros nos encargaremos de conseguirla.'
        },
        {
          question: '¿Por qué IMCMEXICO está creciendo tan rápido?',
          answer: 'Porque nuestros clientes cada vez requieren más máquinas y necesitan medios eficientes para ver nuestros catálogos de maquinaria en la página Web profesional a nivel internacional por Internet.',
        },
        {
          question: '¿Por qué me conviene anunciarme en IMCMEXICO?',
          answer: 'Porque es la mejor manera de cubrir nuestro gran mercado Internacional. Nuestros clientes requieren de productos de calidad para la industria de la construcción.'
        },
        {
          question: '¿Cómo puedo accesar a los servicios que ofrece IMCMEXICO?',
          answer: 'Sólo tiene que registrarse en nuestro sitio Web y automáticamente contará con los productos que ofrece IMCMEXICO.'
        },
        {
          question: '¿Quiénes son los usuarios que se anuncian dentro del sistema de IMCMEXICO?',
          answer: 'Son empresas y personas que ofrecen maquinaria pesada, del sector de la construcción y, desean comercializarla a través de nuestra página Web, la cual está abierta tanto a grandes empresas como a las personas que quieran anunciarse en un sistema amigable y de uso sencillo.'
        },
        {
          question: '¿Qué necesito para publicar mi inventario de maquinaria?',
          answer: 'Sólo necesita registrarse en nuestro sitio Web e introducir la información y fotografías de sus equipos.'
        },
        {
          question: '¿Cómo me registro en IMCMEXICO?',
          answer: 'Sólo tiene que irse al link REGISTRO y dar clic a la opción que más le convenga de acuerdo a sus propósitos, posteriormente llenar el formulario que aparece en pantalla y listo, usted obtendrá un nombre de usuario y clave.'
        },
        {
          question: '¿Cuánto tiempo me toma registrarme e insertar la información de cada máquina?',
          answer: 'El registro solo le llevará dos minutos y, para insertar la información y fotografías de cada equipo tan solo 3 minutos.'
        },
        {
          question: '¿Por qué es importante registrarme e insertar la información en IMCMEXICO?',
          answer: 'Toda la información que usted proporciona, es enviada automáticamente a la cuenta de correo insertada en la forma de registro y así, usted mismo, con su nombre de usuario y clave podrá manejar su propia información en el sistema en el momento que lo desee.'
        },
        {
          question: 'Después de haberme registrado, ¿qué debo hacer?',
          answer: 'Una vez que obtenga su nombre de usuario y clave deberá ingresarlas en www.imcmexico.com.mx cada vez que use los servicios de IMCMEXICO.'
        },
        {
          question: '¿Qué costo tiene registrarme en IMCMEXICO?',
          answer: 'Ninguno. El registro en nuestro sitio Web es totalmente gratis.'
        },
        {
          question: 'Las cotizaciones de la maquinaria que aparece en el portal de IMCMEXICO, ¿son reales?',
          answer: 'Sí, los precios que manejamos en nuestro portal, son precios de lista, que pueden variar para su beneficio dependiendo del acuerdo al que se llegue con el vendedor, en el momento que usted decida adquirir el equipo.'
        },
        {
          question: '¿Qué ventajas obtengo como usuario registrado de IMCMEXICO?',
          answer: 'Una de las grandes ventajas de ser usuario registrado de IMCMEXICO, es que usted podrá generar sus propios Proyectos de Compra de maquinaria usada en forma automatizada, confiable y obtener cotizaciones más precisas cuando desee adquirir maquinaria. En el caso de publicación de productos y servicios, usted podrá manejar su información cada vez que accese a nuestro portal.'
        },
        {
          question: '¿Existe alguna condición o pago por el uso del sitio Web de IMCMEXICO?',
          answer: 'Usted puede consultar nuestro sitio Web sin ningún costo, y si desea publicar sus productos y servicios, el primer mes es totalmente gratis.'
        },
        {
          question: '¿Qué sucede si un cliente se interesa en una máquina de mi inventario?',
          answer: 'Cuando tenemos un cliente que está interesado en comprar un equipo de su inventario, nuestro personal le preguntará si ese equipo está disponible y cuál es su última oferta. Si hay acuerdo con el cliente, entonces podremos negociar.'
        },
        {
          question: '¿El sitio Web muestra quién es el dueño del equipo?',
          answer: 'Sí, en el caso que usted desee usar su propio logo e información del contacto. En este caso cobramos una comisión de $50.00 USD por cada equipo durante el primer mes, a partir del segundo mes cobramos $100. Usted podrá obtener 50% de descuento en toda su publicidad durante 6 meses, si invita a otra empresa a formar parte de los usuarios registrados de IMCMEXICO. Si elige esta alternativa nosotros no sabemos quién será el comprador porque ellos se pondrán en contacto directamente con usted, pero si necesita nuestra asistencia, IMCMEXICO estará a sus órdenes para ayudarlo con sus clientes del extranjero en el momento que lo solicite.'
        },
        {
          question: '¿Existe algún costo por publicar mis máquinas sin mostrar mis datos?',
          answer: 'No, si usted quiere usar los servicios de IMCMEXICO, automáticamente la información del contacto que se muestra es la de nuestras oficinas, por su propia seguridad. En este caso, no tiene que pagar ninguna comisión por el uso de nuestro sistema y, no existe ningún límite para poner toda la información de los equipos que usted desee publicar.'
        },
        {
          question: 'Si la máquina se vende, ¿IMCMEXICO cobra alguna comisión?',
          answer: 'Sí, solo cuando la máquina se vende a través de los servicios de IMCMEXICO, en este caso nosotros cobramos al proveedor el 1% del valor factura del equipo, en compensación a la asistencia que IMCMEXICO brinda a usted y a el comprador hasta el destino final del equipo.'
        },
        {
          question: '¿Cómo realizo el pago por los servicios a IMCMEXICO?',
          answer: 'Usted podrá realizar el pago correspondiente a través de nuestro sistema electrónico PayPal, pago bancario o a través de un depósito bancario.',
        },
        {
          question: '¿Cuáles son los datos bancarios de IMCMEXICO?',
          answer: 'Los depósitos por el pago de los servicios a IMCMEXICO deberán efectuarse a nombre de GRUPO SEG PRE S.A. DE C.V., ó a IMCMEXICO INC, cuyos datos bancarios completos se encuentran en el link denominado: DATOS BANCARIOS DE IMCMEXICO.'
        },
        {
          question: 'Además del servicio de gestión, ¿qué otro servicio me ofrece IMCMEXICO para la adquisición de equipos?',
          answer: 'IMCMEXICO también ofrece la venta directa de maquinaria de construcción por pedido. Para ello basta con depositar un anticipo, el cual será determinado en el momento de concretarse la solicitud.'
        },
        {
          question: '¿Existe algún costo, comisión u otros gastos ocultos relacionados la publicación de la maquinaria en sitio Web de IMCMEXICO?',
          answer: 'Ninguno. No existe ningún otro costo, comisión o gastos más que los expuestos anteriormente.'
        },
        {
          question: '¿Cuál es el rol de IMCMEXICO en la venta de nuestra maquinaria, quién factura, quién lleva a cabo la entrega, etc?',
          answer: 'Entre las actividades que realiza IMCMEXICO se encuentra a nivel nacional la gestión de compra venta del equipo, que incluye la inspección, preparación del Certificado Nafta o de Orígen, facturas, servicio de transporte, servicio de aduana, impuestos y entrega.'
        },
        {
          question: '¿Porqué es una buena decisión anunciar mis equipos en IMCMEXICO?',
          answer: 'IMCMEXICO es una gran ventana para la comercialización de maquinaria, en donde usted podrá publicar gratuitamente sus equipos para venta o renta y, siempre tendrá el control total para cambiar la información de cada uno de los equipos para venta o renta, o si desea podrá tenerlas en stand bye en el sistema. Si usted prefiere pagar por anunciarse, usted podrá insertar su propio logo, misión y, toda la información de contacto para mostrarse por su propia cuenta con sus clientes.',
        },
        {
          question: '¿Cómo puedo conocer los servicios de venta y renta de maquinaria que ofrece IMCMEXICO?',
          answer: 'Usted puede navegar plenamente en el sistema sin necesidad de teclear su nombre de usuario y clave, con lo cual usted puede ver estimaciones preliminares de los costos de todas las máquinas pesadas que proveedores ponen a disposición para renta o venta, así como otros datos e imágenes que se publican gratuitamente en el portal.'
        },
        {
          question: '¿En qué consiste la gestión de la compra o renta de los equipos en IMCMEXICO?',
          answer: 'Si usted anuncia su propia maquinaria para venderla o rentarla, nosotros nos encargamos de toda la gestión, como: servicios de transporte, servicios de aduana, entrega del equipo a su destino final, trato con el comprador y el vendedor.'
        },
        {
          question: '¿Cómo obtengo acceso a la compra o renta de maquinaria a través del sistema IMCMEXICO?',
          answer: 'Deberá obtener su membresía gratuita registrando su propio correo electrónico, nombre y teléfono en el formato de registro del portal de IMCMEXICO, en donde en forma rápida usted mismo generará su nombre de usuario y clave con solo llenar de un breve formulario de REGISTRO.'
        },
        {
          question: '¿Puedo importar maquinaria del extranjero a través de IMCMEXICO?',
          answer: 'Sí, usted puede importar maquinaria del extranjero mediante los servicios de gestión de IMCMEXICO, nosotros le facilitaremos datos de contactos de gestores aduaneros para cada país.'
        },
        {
          question: '¿Cómo genero una lista de los equipos que me interesan?',
          answer: 'Para producir una lista, el cliente puede usar el buscador de maquinaria implementado en el sistema y puede también navegar en el catálogo de IMCMEXICO para seleccionar las máquinas que le interesan activando el botón "Generar mi lista de máquinas"'
        }
      ]
    },
    help: {
      title: 'Ayuda',
      description: '',
      content: []
    },
    pageNotFound: {
      title: '¿Podemos ayudarle a encontrar lo que buscaba?',
      content: 'Por favor, navegue a través del menú superior o seleccione alguna de las siguientes opciones:',
      links: [
        {
          label: 'Catálogo de Maquinaria',
          navRef: 'machinery'
        },
        {
          label: 'Acerca de IMCMEXICO',
          navRef: 'aboutUs'
        },
        {
          label: 'Preguntas Frecuentes',
          navRef: 'faq'
        },
        {
          label: 'Ayuda',
          navRef: 'help'
        }
      ]  
    }
  }
}

export default es