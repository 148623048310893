import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'
import html from 'react-inner-html'

import Breadcrumbs from './layout/Breadcrumbs'

const AboutUs = (props) => {
  const navigatorDictionary = props.dictionary.navigator
  const aboutUsDictionary = props.dictionary.modules.aboutUs
  document.title = `IMCMEXICO | ${navigatorDictionary.aboutUs}`

  const breadcrumbItems = [
    {
      label: navigatorDictionary.home,
      url: '/'
    },
    {
      label: navigatorDictionary.aboutUs
    }
  ]

  return (
    <Fragment>
      <Breadcrumbs items={ breadcrumbItems } />
      <h1>{ aboutUsDictionary.title } </h1>
      <Card>
        <Card.Body>
          <Card.Title>{ aboutUsDictionary.mission.title }</Card.Title>
          <Card.Text { ...html(aboutUsDictionary.mission.content) } />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>{ aboutUsDictionary.advantages.title }</Card.Title>
          <Card.Text { ...html(aboutUsDictionary.advantages.content) } />
        </Card.Body>
      </Card>
      <p>{ aboutUsDictionary.content }</p>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(
  mapStateToProps,
  {}
)(AboutUs)