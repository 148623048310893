import _ from 'lodash'
import api from './../../api'
import {
  SET_LANGUAGE,
  SET_DICTIONARY,
  GET_CATEGORIES,
  GET_MANUFACTURERS,
  GET_MODELS,
  GET_MACHINES,
  GET_MACHINE,
  GET_MACHINES_CAROUSEL,
  GET_QUOTATION_DATA,
  GET_QUOTATION_DESTINATIONS
} from './types'
import { dictionaryList } from './../../languages'

export const setLanguage = language => {
  return {
    type: SET_LANGUAGE,
    payload: language
  }
}

export const setDictionary = language => {
  return {
    type: SET_DICTIONARY,
    payload: dictionaryList[language]
  }
}

export const getCategories = params => async dispatch => {
  const categories = (await api.get(`/categories?json=${JSON.stringify(params)}`)).data

  dispatch({
    type: GET_CATEGORIES,
    payload: categories
  })
}

export const getManufacturers = params => async dispatch => {
  const manufacturers = (await api.get(`/manufacturers?json=${JSON.stringify(params)}`)).data

  dispatch({
    type: GET_MANUFACTURERS,
    payload: manufacturers
  })
}

export const getModels = params => async dispatch => {
  const models = (await api.get(`/models?json=${JSON.stringify(params)}`)).data

  dispatch({
    type: GET_MODELS,
    payload: models
  })
}

export const getMachines = params => async dispatch => {
  const machines = (await api.get(`/machines?json=${JSON.stringify(params)}`)).data

  dispatch({
    type: GET_MACHINES,
    payload: machines
  })
}

export const getMachine = (reference, params) => async dispatch => {
  const machine = (await api.get(`/machines/${reference}?json=${JSON.stringify(params)}`)).data

  if (!_.isEmpty(machine) && machine.category) {
    machine.category = _.startCase(machine.category.toLowerCase())
    machine.manufacturer = _.startCase(machine.manufacturer.toLowerCase())
  }

  dispatch({
    type: GET_MACHINE,
    payload: machine
  })
}

export const getMachinesCarousel = () => async dispatch => {
  const machinesCarousel = (await api.get('/machines/carousel')).data

  dispatch({
    type: GET_MACHINES_CAROUSEL,
    payload: machinesCarousel
  })
}

export const getMachineQuotationData = params => async dispatch => {
  const quotationData = (await api.get(`/machines/quotations?json=${JSON.stringify(params)}`)).data

  dispatch({
    type: GET_QUOTATION_DATA,
    payload: quotationData
  })
}

export const getQuotationDestinations = params => async dispatch => {
  const destinations = (await api.get(`/machines/quotations/destinations?json=${JSON.stringify(params)}`)).data

  dispatch({
    type: GET_QUOTATION_DESTINATIONS,
    payload: destinations
  })
}
