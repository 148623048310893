import _ from 'lodash'

const en = {
  location: 'Location',
  showAllMachines: 'Global',
  showOnlyMexico: 'Only in México',
  includeRecentlySold: 'Include Recently Sold Machines',
  general: {
    loading: 'Loading...',
    yes: 'Yes',
    no: 'No',
    ascending: 'Ascending',
    descending: 'Descending'
  },
  navigator: {
    home: 'Home',
    covid: 'COVID-19 Equipment',
    machinery: 'Machinery',
    catalog: 'Catalog',
    search: 'Search',
    request: 'Request',
    publish: 'Publish',
    aboutUs: 'About Us',
    faq: 'FAQ',
    help: 'Help',
    advertiseHere: 'Advertise Here',
    signUp: 'Sign Up',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    language: 'Español'
  },
  footer: {
    label: 'IMCMEXICO is a trademark'
  },
  modules: {
    home: {
      title: 'Welcome to IMCMEXICO',
      content: `<p>To quickly consult and quote any opportunity of used machinery from the United States of America. Equip your work by following these three easy steps:</p>
        <ol>
          <li>
          Find and instantly quote the machine you want from our portal, through our <b>Machinery</b> module.
          </li>
          <li>
            We wait for you in the USA to inspect the machine of your interest. Or if you prefer, we will inspect it for you.
          </li>
          <li>
            Pay for your machine and receive it at your door or work site in less than 5 days. Fully legalized and invoiced.
          </li>
        </ol>`
    },
    machinery: {
      title: 'Construction Equipment Catalog',
      category: 'Category',
      manufacturer: 'Manufacturer',
      model: 'Model',
      reference: 'Reference',
      placeholderCategory: 'Select a Category',
      placeholderManufacturer: 'Select a Manufacturer',
      placeholderModel: 'Select a Model',
      placeholderReference: 'Place a Reference',
      topCategories: 'Top Categories',
      topManufacturers: 'Top Manufacturers',
      topModels: 'Top Models',
      otherCategories: 'Other Categories',
      otherManufacturers: 'Other Manufacturers',
      otherModels: 'Other Models',
      sorting: {
        byPrice: 'Sort by Price'
      },
      doFilter: 'Filter',
      nomenclature: 'Nomenclatura',
      machineAvailable: 'Machine Available',
      machineValidate: 'Check Availability',
      machineNotFound: 'No match found.',
      description: 'Description',
      year: 'Year',
      hours: 'Hours',
      contact: {
        name: 'Contact',
        phone: {
          main: 'Main Phone',
          secondary: 'Secondary Phone',
          cell: 'Cell'
        },
        email: 'E-mail'
      },
      machineLocation: 'Location',
      quotation: {
        title: 'Quote this machine to your detination',
        description: 'Choose the destination and desired platform to move your machine and instantly get a preliminary quote.',
        quotation: 'Quotation',
        origin: 'Origin',
        destination: 'Destination',
        destinationTo: {
          usa: 'Shipment to South America',
          mex: 'Shipment to Mexico'
        },
        platformType: 'Platform Type',
        platformsList: [
          {
            label: 'Flat (up to 10 ton.)',
            value: 'flat'
          },
          {
            label: 'Semi (up to 20 ton.)',
            value: 'semi'
          },
          {
            label: 'LowBoy (up to 40 ton.)',
            value: 'lowboy'
          }
        ],
        shippingInsurance: 'Shipping Insurance',
        price: 'Machine Price',
        shipment: {
          usa: 'Shipment to USA',
          mex: 'Shipment to Mexico',
        },
        fee: 'Fee',
        handling: 'Handling',
        total: 'Total',
        note: 'Approximate quotation and valid mainly for non-oversized and one-piece machines.'
      },
      viewDetails: 'View Details',
      requestMessage: `Hi, could you give me more information about the following machine?`,
      sharingMessage: `Hi, I'd like to share with you the information about the following machine:`,
      buttons: {
        request: 'Request info',
        share: 'Share details',
        pdf: {
          loading: 'Loading...',
          download: 'Download PDF'
        }
      },
      invalid: {
        category: 'is not a valid Category.',
        manufacturer: 'is not a valid Manufacturer.',
        model: 'is not a valid Model.'
      },
      loadingModal: {
        title: 'Loading data...'
      },
      sharingModal: {
        title: 'Share Details'
      },
      pdf: {
        title: 'Relación de Gestión y Cotización de Maquinaria',
        subtitle: 'Detailed Sheet generated on ',
        dateFormat: 'dddd MMMM Do, YYYY [at] hh:mm a'
      }
    },
    aboutUs: {
      title: 'About IMCMEXICO',
      content: 'Trading with IMCMEXICO heavy used machines or publishing any product or services related with the growing construction market never been that easy.',
      mission: {
        title: 'Mission',
        content: `<p>To consolidate us, as leader company of strategic support for constructors, for the commercialization of used equipment through Internet, with collaboration of personal assistance with quality and professional ethics.</p>
          <p>Our Mission during 2013 is join efforts and partnership with more constructors who are looking to purchase heavy machines.</p>
          <p>Is well known that many countries, but in special Mexico, is an important final destination of used heavy machinery for construction sold from USA and others developed countries.</p>
          <p>That's why from almost 10 years ago, we have purchased and moved into Mexico and others countries, with care and safety machines to our final customers mostly inside of Mexico. Our deliveries include satellite tracking and insurance.</p>
          <p>Some of your customers require our transfer services and import into Mexico for less money and time. We give a hand when needed at no extra charge to the seller.</p>
          <p>We offer free advertisement, to announce any machine in IMCMEXICO, it is free, easy and fast. When a machine is published in <a href="https://imcmexico.mx" target="_blank">imcmexico.mx</a>, our provider don't have to lost time to answer phone calls, because we will take all the phone calls when the provider needed, and when we contact the owner only when have a serious customer with strong intentions to purchase.v
          <p><a href="https://imcmexico.mx" target="_blank">imcmexico.mx</a>, is an open source for everyone for free posting. Our web site is the most seen in Mexico. Many US machinery providers and independent brokers are publishing their machines for free, and also it is free for everyone who want to sell heavy machines for construction in Mexico and other important Spanish countries.</p>
          <p>We are strong in logistics to move machines and delivery port to port with satellite and insurance coverage.</p>`
      },
      advantages: {
        title: 'Advantages',
        content: `<p>Every day more users visit IMCMEXICO, it is the preferred web page for the Mexican constructors and now we are expanding our free services, because our growing number of customers are requiring good quality used heavy machines.</p>
          <p>Once you a registered, you will be able to publish through our <b>Machinery Section</b>, for rent or sale your own equipment using your corporate image and company contact information (upon request). Therefore, you can also promote directly to more customers your products and services using your corporate image.</p>
          <p>It is very easy to register, it takes just 5 minutes without any charge. When you become a user of IMCMEXICO, you will have access to the following services:</p>
          <h6>MACHINERY</h6>
          <ul>
            <li>
              Sell or trade your heavy used machinery to more customers from Mexico, United States and Canada and rest of the world.
            </li>
            <li>
              Import or export heavy used machinery located in Mexico or others countries.
            </li>
            <li>
              Rent your own heavy machinery to main construction companies located in Mexico, but no limited to USA and rest of the world.
            </li>
            <li>
              Sell new spare parts or attachments for heavy machines.
            </li>
            <li>
              You can also publish your technical resume for free.
            </li>
          </ul>
          <h6>ENGINEERING</h6>
          <ul>
            <li>
              Customized search of products and services related to the engineering industry.
            </li>
            <li>
              Trade or acquisition Engineering Services and Products from Mexico, United States, Canada and rest of the World.
            </li>
            <li>
              Publication of your Technical Profile or search of other Specialists.
            </li>
            <li>
              Publication of your Engineering Services and Products.
            </li>
            <li>
              Publication of your Company's Corporative Image.
            </li>
          </ul>
          <h6>CONSTRUCTION</h6>
          <ul>
            <li>
              Customized search of products and services related to the construction industry.
            </li>
            <li>
              Trade or acquisition of Construction Services and Products from Mexico, United States, Canada and the rest of the World
            </li>
            <li>
              Publication of your Company's Corporative Image.
            </li>
            <li>
              Publication of your Construction Products and Services.
            </li>
          </ul>`
      }
    },
    faq: {
      title: 'Frequently Asked Questions',
      description: 'Click on a question to display its corresponding answer.',
      content: [
        {
          question: 'What is IMCMEXICO market?',
          answer: 'IMCMEXICO reaches a wide range of companies in Mexico, USA, Canada and Europe.'
        },
        {
          question: 'Does IMCMEXICO have agreements with construction Mexican companies?',
          answer: 'Yes, we have agreements for several construction Mexican companies to renew their heavy equipments.'
        },
        {
          question: 'How big are the companies that advertise in IMCMEXICO Web site?',
          answer: 'The companies that are part of our database are international companies based in the US, Canada, Mexico and Europe, which have shown a serious commitment to provide high quality equipment and services for the construction industry.'
        },
        {
          question: 'Why has MEXICO increased the acquisition of heavy machinery from overseas?',
          answer: `Due to the current Mexico's economical stability, more constructions are talking place, especially as far as roads and big construction is concerned. Besides, there are no big heavy machinery factories based in Mexico.`
        },
        {
          question: 'How can IMCMEXICO support their customers in their machinery acquisition?',
          answer: 'Every day IMCMEXICO is improving its system and investing in promoting the heavy machineries posted by our providers in our Web site in order to reach basically all the Mexican construction companies.'
        },
        {
          question: 'Are the quotations that appear on IMCMEXICO portal for real?',
          answer: 'Yes, the prices that we handled in our portal are list prices. They can vary, depending on the agreement we reach with the providers at the moment to acquire the equipments.'
        },
        {
          question: 'How long will the promotions that appear in the table of services and costs be effective?',
          answer: 'The promotion will be effective throughout the year.'
        },
        {
          question: 'If I require a machine that is not in the IMCMEXICO Web Site, can I request your services to help me find it?',
          answer: 'If you need a machine that is not listed in our Web Site, click on "Requests" (left side), fill the form and we will find it for you.'
        },
        {
          question: 'Why is IMCMEXICO growing so fast?',
          answer: 'Because our Mexican customers are requiring more machines and they need efficient media to see the catalogue of diverse machines posted into our professional and well-developed Web Site.'
        },
        {
          question: 'Why is it important for my company to post machines into the IMCMEXICO system?',
          answer: 'Because is the best way to cover our growing market in Mexico. Our customers require photos and technical data of the machines posted, along with prices, location and serial numbers to quickly make comparisons and decisions.'
        },
        {
          question: 'How I can access the services offered by IMCMEXICO?',
          answer: 'You just need to be registered in our Web Site and you will automatically have access to all the services we offer.'
        },
        {
          question: 'Who are the users who will see the machines posted into IMCMEXICO data system?',
          answer: 'They are companies or people that own heavy machineries and that are willing to trade them through our user friendly website, which is open to everybody who wants to use it.'
        },
        {
          question: 'What do I need to publish my inventory?',
          answer: 'You just need to be registered in our Web Site, and to have included the data and pictures for each machine posted.'
        },
        {
          question: 'How do I register in IMCMEXICO database?',
          answer: 'You have to click on the REGISTRY link and chose the option that is more according to your intentions. Then, fill out the form that appears on the screen and, you will get access by yourself.'
        },
        {
          question: 'How long does it take to register and to insert data for each machine?',
          answer: 'Filling the registration form takes only two minutes, and inserting data and pictures will take you just 3 minutes per machine.',
        },
        {
          question: 'Why is it important to be registered and to post data into IMCMEXICO data system?',
          answer: 'Because all the information you post into IMCMEXICO system is automatically sent to the email specified in your register form. This way, you get the confirmation of your username and password that allow you to manage your own data at any time.'
        },
        {
          question: 'What do I have to do upon registration?',
          answer: `Once you get your user name and password, you'll have to enter them at www.imcmexico.com.mx every time you use IMCMEXICO services.`
        },
        {
          question: 'How much does it cost to be registered in your Web site?',
          answer: 'Nothing. Registering in our Web site is totally free.',
        },
        {
          question: 'What kind of advantages do I have as an IMCMEXICO registered user?',
          answer: 'One of the great advantages of being a registered user of IMCMEXICO is that you will be able to generate your own Projects of Purchase of used machinery in an automated and reliable way. Most importantly, you will be able to receive more precise quotations of your possible purchases of machinery, since you will be able to easily get, in real time, a customized follow up of the heavy machinery you are interested in. If you are advertising products and services, you will be able to change, update and delete that information at any time, once logged on the system.',
        },
        {
          question: `Is there any condition or fee for the use of the IMCMEXICO's Web Site system?`,
          answer: `You can enter our Web Site and conduct searches anytime, free of charge. Should you want to promote your products and services too, you'll have to pay later on, but the first month is free of charge.`
        },
        {
          question: 'What happens if a client is interested in a machine from my inventory?',
          answer: `When we have a client interested in buying equipment from your inventory, our personnel will ask you if that equipment is still available and which would be the lowest price you would accept for it. If there is agreement with the client's expectations, then we will be able to negotiate.`
        },
        {
          question: `Does the owner of the equipment's information appear on IMCMEXICO Web Site?`,
          answer: `Yes, but only if you wish to use your own logo and contact information. In this case, we charge a commission of $5 USD for each equipment during the first month and from the second month, onwards we charge $10 USD. You will be able to obtain 50% of discount in all your publicity during 6 months, if you invite another company to register as a user of our system. You can insert up to four pictures for each machine, no matter how many machines you want to advertise. We set no limits for that. If you choose this alternative, we won't know who the buyer is, since he will get in touch with you. However, if you need our attendance, IMCMEXICO will be ready to help you with your foreigner clients whenever you ask.`
        },
        {
          question: 'Is there any cost for publishing my machines without showing my data?',
          answer: `No, if you want to use the services of IMCMEXICO, the information that appears on the systems is the one of our offices, for your own security and privacy. In this case, you don't have to pay any fee for using our system, and there is not any limit to put data of the machines you want to promote.`
        },
        {
          question: 'If the machine is sold, does IMCMEXICO charge a fee?',
          answer: 'Yes, only when the machine is sold by IMCMEXICO broker services you will have to pay a fee. In this case, we ask for 1% commission of the total value of the machine, paid by the seller, in exchange for our assistance to you and the buyer in everything until the delivery of the equipment is completed.'
        },
        {
          question: 'Are there any cost, commission or other expense associated to the advertising of our machines in IMCMEXICO Web Site?',
          answer: 'None. There are not any hidden costs, fees, or other expenses apart from what is described above.'
        },
        {
          question: `What is IMCMEXICO's involvement in the sale of our equipment, who does the invoicing, delivery arrangements and others?`,
          answer: 'Among the services provided by IMCMEXICO figure the management of buying and selling of equipment and, in an international level, we assist with the inspections, preparation of the Nafta Certificate, invoices, transportation, customs, taxes and delivery.'
        },
        {
          question: 'Among the services provided by IMCMEXICO figure the management of buying and selling of equipment and, in an international level, we assist with the inspections, preparation of the Nafta Certificate, invoices, transportation, customs, taxes and delivery.',
          answer: 'IMCMEXICO provides you with a new big window for machinery trading in Mexico, through which you will be able to display for free any equipment you have for selling or renting. You always have the total control to change the scheme for each machine, defining when or which ones are for sell, for rent, or just to keep in your inventory. If you prefer to pay for advertising and you actually pay for it, you can insert your own logo, mission, and all the contact information that you want to display to be contacted by your customers.',
        },
        {
          question: 'How do I obtain access to the purchase or rent of machinery through the IMCMEXICO?',
          answer: 'You have to become a member, which is free, by registering your email, name and telephone number on IMCMEXICO portal. Here, you can generate your username and password by filling the form of registry'
        },
        {
          question: 'Can I import machinery from abroad through IMCMEXICO?',
          answer: 'Yes, you can buy machinery from abroad by using the management services of IMCMEXICO.'
        },
        {
          question: 'How do I generate a list with the machinery I am interested in?',
          answer: `In order to generate a list, the client can use the machinery search engine implemented on the system and he can also browse our catalogue to select the machines that interest him, by activating the button "Generate my machinery lists". In order to know more about this topic, please read the FAQ's MACHINERY.`
        }
      ]
    },
    help: {
      title: 'Help',
      description: '',
      content: []
    },
    pageNotFound: {
      title: 'Can we help you find what you are looking for?',
      content: 'Please, navigate through the top menu or select any of the following options:',
      links: [
        {
          label: 'Machinery Catalog',
          navRef: 'machinery'
        },
        {
          label: 'About IMCMEXICO',
          navRef: 'aboutUs'
        },
        {
          label: 'FAQ',
          navRef: 'faq'
        },
        {
          label: 'Help',
          navRef: 'help'
        }
      ]  
    }
  }
}

export default en