import es from './es'
import en from './en'

export const dictionaryList = {
  es,
  en
}

export const languageOptions = [
  {
    id: 'es',
    text: 'Español'
  },
  {
    id: 'en',
    text: 'English'
  }
]