import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Accordion, Card } from 'react-bootstrap'

import Breadcrumbs from './layout/Breadcrumbs'

const Help = (props) => {
  const navigatorDictionary = props.dictionary.navigator
  const helpDictionary = props.dictionary.modules.help

  document.title = `IMCMEXICO | ${navigatorDictionary.help}`

  const breadcrumbItems = [
    {
      label: navigatorDictionary.home,
      url: '/'
    },
    {
      label: navigatorDictionary.help
    }
  ]

  return (
    <Fragment>
      <Breadcrumbs items={ breadcrumbItems } />
      <h1>{ helpDictionary.title }</h1>
      <p className="description">{ helpDictionary.description }</p>
      <Accordion defaultActiveKey="0">
        { helpDictionary.content.map((help, index) => {
          if (!help.question) {
            return false
          }

          return (
            <Card
              key={ `help${index}` }
              className="accordion-card"
            >
              <Accordion.Toggle as={Card.Header} eventKey={ index }>
                { help.question }
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={ index }>
                <Card.Body>{ help.answer }</Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        }) }
      </Accordion>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(
  mapStateToProps,
  {}
)(Help)