import React from 'react'
import { connect } from 'react-redux'
import { Spinner } from 'react-bootstrap'

const LoadingSpinner = (props) => {
  const dictionary = props.dictionary.general

  return (
    <div className="spinner-block">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading</span>
      </Spinner>
      { props.showLabel && dictionary.loading }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(
  mapStateToProps,
  {}
)(LoadingSpinner)