import React from 'react';
import { Router, Route, Switch } from 'react-router-dom'

import history from './helpers/history'
import { LanguageProvider } from './context/Language'
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import PageNotFound from './components/PageNotFound'
import Home from './components/Home'
import AboutUs from './components/AboutUs'
import FAQ from './components/FAQ'
import Help from './components/Help'
import MachineryList from './components/machinery/MachineryList'
import MachineDetails from './components/machinery/MachineryDetails'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/main.css'
import './styles/carousel.css'
import './styles/colors.css'
import './styles/header.css'
import './styles/footer.css'
import './styles/pagination.css'

function App() {
  return (
    <Router history={ history }>
      <LanguageProvider>
        <Header />
        <div className="content">
          <Switch>
            <Route path="/" exact component={ Home } />
            <Route path="/maquinaria/:category/:manufacturer/:model/:reference" component={ MachineDetails } />
            <Route path="/machinery/:category/:manufacturer/:model/:reference" component={ MachineDetails } />
            <Route path="/maquinaria/:category/:manufacturer/:model" component={ MachineryList } />
            <Route path="/machinery/:category/:manufacturer/:model" component={ MachineryList } />
            <Route path="/maquinaria/:category/:manufacturer" component={ MachineryList } />
            <Route path="/machinery/:category/:manufacturer" component={ MachineryList } />
            <Route path="/maquinaria/:category" component={ MachineryList } />
            <Route path="/machinery/:category" component={ MachineryList } />
            <Route path="/maquinaria" component={ MachineryList } />
            <Route path="/machinery" component={ MachineryList } />
            <Route path="/nosotros" component={ AboutUs } />
            <Route path="/about-us" component={ AboutUs } />
            <Route path="/preguntas-frecuentes" component={ FAQ } />
            <Route path="/faq" component={ FAQ } />
            <Route path="/ayuda" component={ Help } />
            <Route path="/help" component={ Help } />
            <Route path="*" component={ PageNotFound } />
          </Switch>
        </div>
        <Footer />
      </LanguageProvider>
    </Router>
  );
}

export default App;
